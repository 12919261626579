<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#recientes" type="button" role="tab" aria-controls="recientes" aria-selected="true">Solicitudes Recientes</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#historial" type="button" role="tab" aria-controls="historial" aria-selected="false">Historial</button>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <!-- TAB RECIENTES -->
  <div class="tab-pane fade show active  bg-body" id="recientes" role="tabpanel" aria-labelledby="recientes-tab">

    <div class="container bg-body mt-4">
      <div class="card card-primary card-outline" style="width: 22rem;display: inline-block;margin-right: 1.25rem;vertical-align: top;">
        <div class="card-header font-weight-bold bg-light" style="border-bottom:0px solid">
          SOLICITUDES ( {{count_solicitudes}} )
          <div class="card-tools">
            <button *ngIf="!cargando" type="button" class="btn btn-tool btn-sm" (click)="getListSolicitudes()">
              <i class="fas fa-sync"></i>
            </button>
            <button *ngIf="cargando" type="button" class="btn btn-tool btn-sm">
              <i class="fa fa-sync-alt fa-spin"></i>
            </button>
            
            <a href="#" data-bs-toggle="modal" data-bs-target="#nuevaSolicitud" class="btn btn-tool btn-sm">
            <i class="fas fa-plus"></i>
            </a>
          </div>
        </div>
        <div class="card-body bg-light" style="overflow-y: auto; max-height: 600px;">
          <!-- item -->
          <div *ngFor="let item of listSolicitudes" (click)="verSolicitud(item)" class="card" style="transition: all 0.15s ease 0s; height: inherit; width: inherit;cursor: pointer;">      
            <div class="card-header bg-body">
              <img width="36px"
              height="36px"
              [src]="item.fotografia"
              class="user-image img-circle elevation-2"
              alt="User Image"
              style="object-fit: cover; border-radius: 50%;" />
         
              <div class="card-tools">
                <h4 class=" font-weight-bold"># {{item.consecutivo}}</h4>
              </div>        
            </div>  
            <div class="card-body">      
              <div><h6>{{item.nombre | titlecase}}</h6></div> 
              <div><h4>{{item.celular}}</h4></div> 
              <div><h5 style="font-size: 0.9em;">{{item.email}}</h5></div> 
              <small>{{item.solicitud_at}}</small> 
            </div>  
                
          </div>      
          <!-- fin item -->
    
        </div>
      </div>
    
      <div class="card card-warning card-outline" style="width: 22rem;display: inline-block;margin-right: 1.25rem;vertical-align: top;">
        <div class="card-header font-weight-bold bg-light" style="border-bottom:0px solid">
          WEB ( {{count_solicitudes_web}})
        </div>
        <div class="card-body bg-light" style="overflow-y: auto; max-height: 600px;">
          <!-- item -->
          <div *ngFor="let item of listSolicitudesWeb" (click)="verSolicitud(item)" class="card" style="transition: all 0.15s ease 0s; height: inherit; width: inherit;cursor: pointer;">      
            <div class="card-header bg-body">
              <span *ngIf="!item.fotografia"><i class="fas fa-2x fa-globe-americas"></i></span>
              <img *ngIf="item.fotografia"
              [src]="item.fotografia"
              width="36px"
              height="36px"
              class="user-image img-circle elevation-2"
              alt="User Image"
              style="object-fit: cover; border-radius: 50%;" />
         
              <div class="card-tools">
                <h4 class=" font-weight-bold"># {{item.consecutivo}}</h4>
              </div>        
            </div>  
            <div class="card-body">      
              <div><h6>{{item.nombre | titlecase}}</h6></div> 
              <div><h4>{{item.celular}}</h4></div> 
              <div><h5 style="font-size: 0.9em;">{{item.email}}</h5></div> 
              <!-- <div id="1240" class="detalleSolicitud" style="display: block;">NO SE FECHA AUN</div>  -->
              <small>{{item.solicitud_at}}</small> 
            </div>  
                
          </div>      
          <!-- fin item -->
          
     
          
          
        </div>
      </div>
    
      <div class="card card-success card-outline" style="width: 22rem;display: inline-block;margin-right: 1.25rem;vertical-align: top;">
        <div class="card-header font-weight-bold bg-light" style="border-bottom:0px solid">
          PROCESADAS ( {{count_solicitudes_procesadas}})
        </div>
        <div class="card-body bg-light" style="overflow-y: auto; max-height: 600px;">
          <!-- item -->
          <div *ngFor="let item of listSolicitudesProcesadas" (click)="verSolicitud(item)" class="card" style="transition: all 0.15s ease 0s; height: inherit; width: inherit;cursor: pointer;">      
            <div class="card-header bg-body">
              <span *ngIf="!item.fotografia"><i class="fas fa-2x fa-globe-americas"></i></span>
              <img *ngIf="item.fotografia"
              [src]="item.fotografia"
              alt="User Image"
              width="36px"
              height="36px"
              class="user-image elevation-2"
              style="border-radius: 50%; object-fit: cover;" />
         
              <div class="card-tools">
                <h4 class=" font-weight-bold"># {{item.consecutivo}}</h4>
              </div>        
            </div>  
            <div class="card-body">      
              <div><h6>{{item.nombre | titlecase}}</h6></div> 
              <div><h4>{{item.celular}}</h4></div> 
              <div><h5 style="font-size: 0.9em;">{{item.email}}</h5></div> 
              <!-- <div id="1240" class="detalleSolicitud" style="display: block;">NO SE FECHA AUN</div>  -->
              <small>{{item.solicitud_at}}</small> 
            </div>  
                
          </div>     
          <!-- fin item -->
      
        </div>
      </div>
    </div>



    
  </div>
  <!-- TAB HISTORIAL -->
  <div class="tab-pane fade" id="historial" role="tabpanel" aria-labelledby="historial-tab">
    <div class="container bg-body">
      <table class="table table-hover table-sm">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Fecha</th>
            <th scope="col">Cliente</th>
            <th scope="col">Asesor</th>
            <th scope="col">Fecha</th>
            <th scope="col">Estado</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of listHistorial">
            <th scope="row">{{item.consecutivo}}</th>
            <td>{{item.solicitud_at}}</td>
            <td>{{item.nombre | titlecase}}</td>
            <td><span *ngIf="!item.usuario_id">WEB</span> <span *ngIf="item.usuario_id">{{item.usuario_nombre}}</span></td>
            <td>{{item.solicitud_up}}</td>
            <td>
              <span *ngIf="item.estado == -1" class="badge bg-danger">Cancelada</span>
              <span *ngIf="item.estado == 3" class="badge bg-success">Procesada</span>
              <span *ngIf="item.estado == 2" class="badge bg-warning">Pendiente</span>
              <span *ngIf="item.estado == 1" class="badge bg-warning">Pendiente</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>





  <!-- Modal Nueva Solicitud -->
  <div class="modal fade" id="nuevaSolicitud" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Nueva Solicitud</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="form-label">Buscar Cliente</label>
                <input [(ngModel)]="buscador" type="text"class="form-control">
              </div> 
              
              <div *ngIf="!buscador"></div>

              <ul class="list-group"*ngIf="buscador">
                <button  *ngFor="let item of listClientes | filtroCliente:buscador" type="button" class="list-group-item list-group-item-action" (click)="selectCliente(item)">
                  {{ item.nombre | titlecase }}
                </button>

                <button class="list-group-item list-group-item-action text-bold" style="background:#343a40cc; color:white;font-size: 1.0em" (click)="createCliente(buscador)">
                  <i class="fa fa-user-plus"> </i> CREA UN CLIENTE.
                </button>
              </ul>
            </div>
            <form [formGroup]="nuevoClienteForm" (ngSubmit)="newCliente()">
              <div class="col-12 mt-3" id="crearCliente" style="display: none;">
                <div class="card">
                  <div class="card-body">
                    <h5>Cliente</h5>
                    <div class="form-group">
                      <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control"/>
                    </div> 
                    
                    <div class="form-group">
                      <input formControlName="celular" placeholder="Celular" type="text" class="form-control"/>
                    </div> 
                    
                    <div class="form-group">
                      <input formControlName="email" placeholder="Email" type="text" class="form-control"/>
                    </div> 

                    <app-button [disabled]="!nuevoClienteForm.value.nombre || !nuevoClienteForm.value.celular || !nuevoClienteForm.value.email" [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                      Crear Cliente
                  </app-button>
                  </div>
                </div>                         
              </div> 

            </form>


            <form [formGroup]="nuevaSolicitudForm" (ngSubmit)="newSolicitud()">
            <div class="col-12 mt-3" *ngIf="nuevaSolicitudForm.value.id">
              <div class="card">
                <div class="card-body">
                  <h5>Cliente</h5>
                  <div>{{nuevaSolicitudForm.value.nombre | titlecase}}</div>
                  <div>{{nuevaSolicitudForm.value.celular}}</div>
                  <div>{{nuevaSolicitudForm.value.email}}</div>
                  <div class="form-group mt-2">
                    <label class="form-label">Detalle <small>Información de la solicitud.</small></label>
                    <textarea
                    formControlName="detalle"
                    type="text"
                    rows="5"
                    class="form-control">
                    </textarea>
                  </div>                     
                </div>
              </div>                         
            </div> 

            <div class="col-12" *ngIf="nuevaSolicitudForm.value.id">
              <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">
                  Crear Solicitud
              </app-button>
            </div>
            </form>
        
            
          </div>
       
        </div>
      </div>
    </div>
  </div>

    <!-- Modal Ver Solicitud -->
  <div class="modal fade " id="verSolicitud" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header " 
        [ngClass]="{'bg-success': itemSolicitud.estado ==3, 'bg-warning': itemSolicitud.estado ==2, 'bg-primary': itemSolicitud.estado ==1 }">
          <h5 class="modal-title" id="exampleModalLabel">
            <span *ngIf="!itemSolicitud.fotografia"><i class="fas fa-2x fa-globe-americas"></i></span>
            <img *ngIf="itemSolicitud.fotografia" width="36px"
            [src]="itemSolicitud.fotografia"
            class="user-image img-circle elevation-2"
            alt="User Image"/>
             Solicitud # {{itemSolicitud.id}} <span *ngIf="itemSolicitud.estado ==3">- PROCESADA</span></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <h5>{{itemSolicitud.nombre | titlecase}}</h5>
            <h3><a href="https://wa.me/57{{itemSolicitud.celular}}?text=Hola soy {{user.nombre}} asesor de {{user.ename}} estamos gestionando su solicitud." target="_blank"><i class="fab fa-whatsapp"></i> {{itemSolicitud.celular}}</a></h3>
            <h5>{{itemSolicitud.email}}</h5>
            <div><hr>
              {{itemSolicitud.detalle}}</div>
            <div>
              <hr>
              <div class="row">
                <div class="col-6">
                  <button [disabled]="itemSolicitud.estado ==3" type="button" class="btn btn-block btn-outline-success" (click)="procesarSolicitud(itemSolicitud,3)"><i class="fa fa-check"></i></button>
                </div>
                <div class="col-6">
                  <button [disabled]="itemSolicitud.estado ==3" type="button" class="btn btn-block btn-outline-danger" (click)="cancelarSolicitud(itemSolicitud,-1)"><i class="fa fa-trash"></i></button>
                </div>                
              </div>
            </div>
          </div>
          
        </div>

      </div>
    </div>
  </div>


      <!-- Modal Ver Confirmacion -->
      <div class="modal fade " id="verConfirmacion" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header bg-warning">
              <h5 class="modal-title" id="exampleModalLabel">
                <img width="36px"
                [src]="itemSolicitud.fotografia"
                class="user-image img-circle elevation-2"
                alt="User Image"/>
                 Solicitud # {{itemSolicitud.id}} <span *ngIf="itemSolicitud.estado ==3">- PROCESADA</span></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <h5>Cual es el motivo de cancelación de esta solicitud?.</h5>
                <textarea [(ngModel)]="itemSolicitud.nota" rows="5" class="form-control"></textarea>
                <div>
                  <hr>
                  <div class="row">
                    <div class="col-6">
                      <button [disabled]="!itemSolicitud.nota" type="button" class="btn btn-block btn-outline-danger" (click)="procesarSolicitud(itemSolicitud,-1)"><i class="fa fa-trash"></i> Eliminar</button>
                    </div>                
                    <div class="col-6">
                      <button [disabled]="itemSolicitud.estado ==3" type="button" class="btn btn-block btn-outline-primary" data-bs-dismiss="modal"> Cancelar</button>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
    
          </div>
        </div>
      </div>