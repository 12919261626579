import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-cotizaciones',
  templateUrl: './cotizaciones.component.html',
  styleUrls: ['./cotizaciones.component.scss']
})
export class CotizacionesComponent implements OnInit {
  public nuevoForm: FormGroup;
  public nuevoClienteForm: FormGroup;
  public editForm: FormGroup;

  public cargando = false;
  findInput: any;
  cotizaciones: any = [];
  pagesNumber: any;
  offset = 3;
  page = 1;
  public user: any = null;
  buscador: any;
  listClientes: any[];
  listPoliticas: any[];
  listPoliticasCotizacion: any[];
  cotizacion_id:any;

  origen: string;
  destino: string;
  partida: string;
  regreso: string;
  titulo: string;

  constructor(public router: Router, private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }

  ngOnInit(): void {
    this.user = this.appService.user;
    this.getList();
    this.getListClientes();

    this.nuevoForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.nullValidator),
      cc: new FormControl(null, Validators.nullValidator),
      email: new FormControl(null, Validators.nullValidator),
      direccion: new FormControl(null, Validators.nullValidator),
      celular: new FormControl(null, Validators.nullValidator),
      ciudad: new FormControl(null, Validators.nullValidator),
      nota: new FormControl(null, Validators.nullValidator),
    });

    this.nuevoClienteForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      cc: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      direccion: new FormControl(null, Validators.required),
      celular: new FormControl(null, Validators.required),
    });

    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      cc: new FormControl(null, Validators.nullValidator),
      email: new FormControl(null, Validators.nullValidator),
      direccion: new FormControl(null, Validators.nullValidator),
      celular: new FormControl(null, Validators.nullValidator),
      ciudad: new FormControl(null, Validators.nullValidator),
    });

    
  }

  getList() {
    this.cargando = true;
    this.findInput = null;
    setTimeout(() => this.appService.getCotizacion(this.user.et + '-' + this.page).subscribe((data) => {
      // console.log(data['tarjeta_reserva']);
      this.cotizaciones = data['obj'];
      this.ForpagesNumber();
      this.cargando = false;
    }), 400);
  }

  ForpagesNumber() {
    let from = this.cotizaciones.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.cotizaciones.total_page) {
      to = this.cotizaciones.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  changePage(page) {
    this.page = page; //para el filtro
    this.cotizaciones.current = page;
    this.getList();
  }

  ver(item) {
    console.log(item);
    // this.contacto_nombre
    // this.tarjetaEditar = true;
    // this.selectPasajeros = null;
    // this.selectVuelos = null;
    this.editForm.get('id').setValue(item.cliente_id);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('email').setValue(item.email);
    this.editForm.get('cc').setValue(item.cc);
    this.editForm.get('direccion').setValue(item.direccion);
    this.editForm.get('celular').setValue(item.celular);
    this.editForm.get('ciudad').setValue(item.ciudad);

    // this.tarjeta_reserva_id = item.id;
    // this.contacto_nombre = item.contacto_nombre;    
    // this.contacto_nombre_borrador = item.contacto_nombre;    

    // this.contacto_celular = item.contacto_celular;
    // this.contacto_celular_borrador = item.contacto_celular;

    // this.referido = item.referido_usuario_id;
    // this.hotel = item.hotel;
    // this.hotel_borrador = item.hotel;
    // this.relacion_hoteles = item.relacion_hoteles;
    // this.relacion_hoteles_borrador = item.relacion_hoteles;
    // this.fecha_llegada = item.fecha_llegada;
    // this.fecha_llegada_borrador = item.fecha_llegada;
    // this.fecha_out = item.fecha_out;
    // this.fecha_out_borrador = item.fecha_out;
    // this.noches = item.noches;

    // this.adulto = item.adulto;
    // this.adulto_borrador = item.adulto;
    // this.adulto_tarifa = item.adulto_tarifa;
    // this.adulto_tarifa_borrador = item.adulto_tarifa;
    // this.nino = item.nino;
    // this.nino_borrador = item.nino;
    // this.nino_tarifa = item.nino_tarifa;
    // this.nino_tarifa_borrador = item.nino_tarifa;
    // this.infante = item.infante;
    // this.infante_borrador = item.infante;
    // this.infante_tarifa = item.infante_tarifa;
    // this.infante_tarifa_borrador = item.infante_tarifa;
    // this.sumTotalCosteo = item.costeo_total;
    // this.total_venta = item.total_venta;
    // this.img = item.img;
    // this.img_archivo_url = item.archivo_url;

    // this.selectPasajeros = item.pasajeros;
    // this.selectVuelos = item.vuelos;
    // this.selectProveedorActividades = item.proveedores;
    // this.selectCosteo = item.costeo;
    // this.selectCAcomodacion = item.acomodacion;

    $('#ver').modal('show');
    // this.getListHistorialAbonos(item.id);
    // this.getListAdjuntos(item.id);
    // this.getListPoliticasTarjetaReserva(item.id);
    // this.calDias(item.fecha_llegada, item.fecha_out);
  }

  getBuscarCliente() {
    let params = {
      buscar: true,
      buscar_input: this.buscador,
      et: this.user.et,
    };
    this.appService.postClientes(params).subscribe((data) => {
      this.listClientes = data['clientes'].items;
      // console.log(this.listIata);   
      this.cargando = false;
    });
  }

  getListClientes() {
    this.appService.getClientes(this.user.et).subscribe((data) => {
      this.listClientes = data['clientes'].items;
      this.cargando = false;
    });
  }

  async newCliente() {
    if (this.nuevoClienteForm.valid) {
      this.cargando = true;
      let params = {
        nuevo: true,
        nombre: this.nuevoClienteForm.value.nombre,
        cc: this.nuevoClienteForm.value.cc,
        celular: this.nuevoClienteForm.value.celular,
        direccion: this.nuevoClienteForm.value.direccion,
        email: this.nuevoClienteForm.value.email,
        et: this.user.et,
      };
      this.appService.postClientes(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.nuevoForm.get('id').setValue(data["data"].id);
            this.nuevoForm.get('nombre').setValue(data["data"].nombre);
            this.nuevoForm.get('cc').setValue(data["data"].cc);
            this.nuevoForm.get('email').setValue(data["data"].email);
            this.nuevoForm.get('direccion').setValue(data["data"].direccion);
            this.nuevoForm.get('celular').setValue(data["data"].celular);
            // this.router.navigate(['/']);
            this.nuevoClienteForm.reset()
            $('#crearCliente').hide();
            this.getListClientes();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.cargando = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  createCliente(buscador) {
    this.buscador = null;
    $('#crearCliente').show();
  }

  selectCliente(item) {
    this.buscador = null;
    this.nuevoForm.get('id').setValue(item.id);
    this.nuevoForm.get('nombre').setValue(item.nombre);
    this.nuevoForm.get('cc').setValue(item.cc);
    this.nuevoForm.get('email').setValue(item.email);
    this.nuevoForm.get('direccion').setValue(item.direccion);
    this.nuevoForm.get('celular').setValue(item.celular);
    // console.log(item);
  }

  async nuevoCotizacion() {
    if (this.nuevoForm.valid) {
      this.cargando = true;
      let params = {
        nuevo: true,
        et: this.user.et,
        ut: this.user.token,
        id: this.nuevoForm.value.id,
        estado: 1,
      };
      console.log(params);
      this.appService.postCotizacion(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            // console.log(this.nuevoForm.value);
            let params = {
              id: data["data"].id,
              cliente_id: this.nuevoForm.value.cliente_id,
              nombre: this.nuevoForm.value.nombre,
              email: this.nuevoForm.value.email,
              cc: this.nuevoForm.value.cc,
              direccion: this.nuevoForm.value.direccion,
              celular: this.nuevoForm.value.celular,
              ciudad: this.nuevoForm.value.ciudad,
            }
            this.ver(params);
            this.nuevoForm.reset()
            $('#nuevo').modal('hide');
            $('#ver').modal('show');

            this.toastr.success(data["msg"]);
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.cargando = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  editarCotizacion(){

  }
  findCotizacion() { }

  resetAll() { }

  getListPoliticas() {
    let params = {
      get: true,
      et: this.user.et,
    };
    this.appService.postPoliticas(params).subscribe((data) => {
      this.listPoliticas = data['obj'];
      this.cargando = false;
    });
  }

  addPolitica(item) {
    let params = {
      add: true,
      et: this.user.et,
      id: item.id,
      cotizacion_id: this.cotizacion_id
    };
    this.appService.postPoliticas(params).subscribe((data) => {
      // this.getListPoliticasTarjetaReserva(this.tarjeta_reserva_id);
      this.cargando = false;
    });

  }

  // deletePolitica(item) {
  //   Swal.fire({
  //     title: '¿Está seguro?',
  //     text: `Desea eliminar esta Politica?`,
  //     icon: 'question',
  //     showCancelButton: true,
  //     confirmButtonText: 'Sí, estoy seguro!',
  //   }).then((result) => {

  //     let params = {
  //       deletePolitica: true,
  //       et: this.user.et,
  //       id: item.id,
  //       tarjeta_id: this.tarjeta_reserva_id
  //     };
  //     this.appService.postPoliticas(params).subscribe((data) => {
  //       this.getListPoliticasTarjetaReserva(this.tarjeta_reserva_id);
  //       this.cargando = false;
  //     });
  //   });

  // }

}
