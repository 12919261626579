import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AppService } from '@services/app.service';
import moment from 'moment';
import confetti from 'canvas-confetti';

declare var $: any;

@Component({
  selector: 'app-visa-publica',
  templateUrl: './visa-publica.component.html',
  styleUrls: ['./visa-publica.component.scss']
})
export class VisaPublicaComponent implements OnInit {
  id:any;
  solicitud_visa: any;
  empresa: any;
  noches: any;
  politicaNombre: any;
  politicaDescripcion: any;
  
  constructor(private route: ActivatedRoute,public router: Router,
    public appService: AppService) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.id = this.route.snapshot.paramMap.get('id');
        console.log(this.id);
        if(this.id){
          this.getVisa(); 
          this.setVisaVisita();
        }else{
          // this.router.navigate(['/catalogo/']);
        } 

      });
   }

  ngOnInit(): void {
  }

  getVisa(){
    let params = {
      getVisaPublica:true,
      token: this.id,
    };
    this.appService.postVisaHistorial(params).subscribe( (data) => {      
      this.solicitud_visa = data['obj']; 

      console.log(this.solicitud_visa?.estado_solicitud)
      if (this.solicitud_visa?.estado_solicitud == 4) {
        this.lanzarConfeti();
      }  
      console.log(this.solicitud_visa);   
    })
  }

  setVisaVisita(){
    let params = {
      reporteVisitaCliente:true,
      token: this.id,
    };
    this.appService.postVisaHistorial(params).subscribe( (data) => {      
      console.log(data['msg']);   
    })
  }


  lanzarConfeti() {
    const duration = 3 * 1000; // Duración en milisegundos
    const end = Date.now() + duration;

    (function frame() {
      // Si ha pasado el tiempo, termina
      if (Date.now() < end) {
        confetti({
          particleCount: 5,
          angle: 60,
          spread: 55,
          origin: { x: 0 },
        });
        confetti({
          particleCount: 5,
          angle: 120,
          spread: 55,
          origin: { x: 1 },
        });
        requestAnimationFrame(frame);
      }
    })();
    console.log('se lanzo')
  }



}
