import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
  styleUrls: ['./reservas.component.scss']
})
export class ReservasComponent implements OnInit {
  public nuevoForm: FormGroup;
  public editForm: FormGroup;
  public nuevoClienteForm: FormGroup;
  public nuevoPasajeroForm: FormGroup;
  public nuevoVueloForm: FormGroup;
  public nuevoCosteoForm: FormGroup;
  public editPasajeroForm: FormGroup;

  public user: any = null;

  public tarjetaEditar = false;
  public isAuthLoading = false;


  reporteExport: any;
  findInput: any;
  tarjeta_reserva: any = [];
  pagesNumber: any;
  offset = 3;
  page = 1;
  pageMedio = 1;
  public cargando = false;

  buscador: any;
  listClientes: any[];
  listAsesores: any[];
  listPasajeros: any[];
  listIata: any[];
  listProveedores: any[];
  listProveedoresActividades: any[];
  listHistorialAbonos: any[];
  listPoliticas: any[];
  listPoliticasTarjetaReserva: any[];
  listAdjuntos: any[];
  listGaleriaMedios: any[];
  adjunto_nombre: any;
  adjunto_archivo: any;
  adjunto_archivo_url: any;
  adjunto_archivo_extension: any;

  selectPasajeros = [];
  selectVuelos = [];
  selectProveedorActividades = [];
  selectCosteo = [];
  selectCAcomodacion = [];
  selectCAcomodacionGeneral = [];
  selectPasajerosDisponibleAcomodacion = [];

  selectPasajerosAcomodacion = [];

  tarjeta_reserva_id: any;
  hotel: any;
  hotel_borrador: any;
  relacion_hoteles: any;
  relacion_hoteles_borrador: any;
  fecha_llegada: any;
  fecha_llegada_borrador: any;
  fecha_out: any;
  fecha_out_borrador: any;
  noches: any;
  adulto: any
  adulto_borrador: any
  adulto_tarifa: any;
  adulto_tarifa_borrador: any;
  nino: any;
  nino_borrador: any;
  nino_tarifa: any;
  nino_tarifa_borrador: any;
  infante: any
  infante_borrador: any
  infante_tarifa: any;
  infante_tarifa_borrador: any;
  total_venta: number = 0;

  contacto_nombre: any;
  contacto_nombre_borrador: any;

  contacto_celular: any;
  contacto_celular_borrador: any;
  referido: any;
  usuario_id: any;
  costeo_total: any;
  sumTotalCosteo: number = 0;
  img: any;
  img_archivo_url: any;

  tipoAcomodacion: any;
  tipoAcomodacionI: any;

  abono_valor: any;
  abono_reserva: any;

  editar_abono = false;
  abono_id: any;
  comprobante: any;
  comprobante_url: any;
  detalle: any;
  selectImagen: any;

  public loadingImg = false;

  constructor(public router: Router, private toastr: ToastrService, private appService: AppService, private http: HttpClient) { }
  ngOnInit(): void {
    this.user = this.appService.user;
    this.getList();
    this.getListClientes();
    this.getListAsesores();
    this.getListPasajeros();
    this.getListIata();
    this.getListProveedores();
    this.getListPoliticas();
    this.getListGaleriaMedios();


    this.nuevoForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.nullValidator),
      cc: new FormControl(null, Validators.nullValidator),
      email: new FormControl(null, Validators.nullValidator),
      direccion: new FormControl(null, Validators.nullValidator),
      celular: new FormControl(null, Validators.nullValidator),
      ciudad: new FormControl(null, Validators.nullValidator),
      nota: new FormControl(null, Validators.nullValidator),
    });

    this.editForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      cc: new FormControl(null, Validators.nullValidator),
      email: new FormControl(null, Validators.nullValidator),
      direccion: new FormControl(null, Validators.nullValidator),
      celular: new FormControl(null, Validators.nullValidator),
      ciudad: new FormControl(null, Validators.nullValidator),
    });

    this.nuevoClienteForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      cc: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      direccion: new FormControl(null, Validators.required),
      celular: new FormControl(null, Validators.required),
    });

    this.nuevoPasajeroForm = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      cc: new FormControl(null, Validators.required),
      adulto: new FormControl(null, Validators.required),
      fecha_nacimiento: new FormControl(null, Validators.required),
      nota: new FormControl(null, Validators.nullValidator),
    });

    this.nuevoVueloForm = new FormGroup({
      cia: new FormControl(null, Validators.required),
      vuelo: new FormControl(null, Validators.nullValidator),
      origen: new FormControl(null, Validators.required),
      destino: new FormControl(null, Validators.required),
      clase: new FormControl(null, Validators.nullValidator),
      dia: new FormControl(null, Validators.nullValidator),
      mes: new FormControl(null, Validators.nullValidator),
      sale: new FormControl(null, Validators.nullValidator),
      llega: new FormControl(null, Validators.nullValidator),
    });


    this.nuevoCosteoForm = new FormGroup({
      detalle: new FormControl(null, Validators.required),
      cantidad: new FormControl(null, Validators.required),
      valor: new FormControl(null, Validators.nullValidator),
    });


    this.editPasajeroForm = new FormGroup({
      id: new FormControl(null, Validators.required),
      nombre: new FormControl(null, Validators.required),
      cc: new FormControl(null, Validators.required),
      adulto: new FormControl(null, Validators.required),
      fecha_nacimiento: new FormControl(null, Validators.required),
      nota: new FormControl(null, Validators.nullValidator),
    });

  }


  resetAll() {
    this.tarjetaEditar = false;
    this.contacto_nombre = null;
    this.contacto_nombre_borrador = null;
    this.contacto_celular = null;
    this.contacto_celular_borrador = null;
    this.referido = null;
    this.hotel = null;
    this.hotel_borrador = null;
    this.relacion_hoteles = null;
    this.relacion_hoteles_borrador = null;
    this.fecha_llegada = null;
    this.fecha_llegada_borrador = null;
    this.fecha_out = null;
    this.fecha_out_borrador = null;
    this.noches = null;
    this.adulto = null;
    this.adulto_borrador = null;
    this.adulto_tarifa = null;
    this.adulto_tarifa_borrador = null;
    this.nino = null;
    this.nino_borrador = null;
    this.nino_tarifa = null;
    this.nino_tarifa_borrador = null;
    this.infante = null;
    this.infante_borrador = null;
    this.infante_tarifa = null;
    this.infante_tarifa_borrador = null;
    this.costeo_total = null;
    this.total_venta = null;
    this.selectPasajeros = [];
    this.selectVuelos = [];
    this.selectProveedorActividades = [];
    this.selectCosteo = [];
    this.selectCAcomodacion = [];
    this.selectCAcomodacionGeneral = [];
    this.selectPasajerosDisponibleAcomodacion = [];
    this.sumTotalCosteo = 0;
    this.img =null;
    this.img_archivo_url =null;
  }

  getList() {
    this.cargando = true;
    this.findInput = null;
    setTimeout(() => this.appService.getTarjetaReserva(this.user.et + '-' + this.page).subscribe((data) => {
      // console.log(data['tarjeta_reserva']);
      this.tarjeta_reserva = data['tarjeta_reserva'];
      this.ForpagesNumber();
      this.cargando = false;
    }), 400);
  }

  getListClientes() {
    this.appService.getClientes(this.user.et).subscribe((data) => {
      this.listClientes = data['clientes'].items;
      this.cargando = false;
    });
  }

  getBuscarCliente() {
    let params = {
      buscar: true,
      buscar_input: this.buscador,
      et: this.user.et,
    };
    this.appService.postClientes(params).subscribe((data) => {
      this.listClientes = data['clientes'].items;
      // console.log(this.listIata);   
      this.cargando = false;
    });
  }
  
  getBuscarPasajero() {
    let params = {
      buscar: true,
      buscar_input: this.buscador,
      et: this.user.et,
    };
    this.appService.postPasajeros(params).subscribe((data) => {
      this.listPasajeros = data['clientes'].items;
      // console.log(this.listIata);   
      this.cargando = false;
    });
  }

  getListAsesores() {
    let params = {
      asesores: true,
      et: this.user.et,
    }
    this.appService.postUsuario(params).subscribe((data) => {
      this.listAsesores = data['obj'];
      this.cargando = false;
    });
  }

  getListPasajeros() {
    this.appService.getPasajeros(this.user.et).subscribe((data) => {
      this.listPasajeros = data['pasajeros'].items;
      // console.log(this.listPasajeros);   
      this.cargando = false;
    });
  }

  getListIata() {
    let params = {
      get: true,
      et: this.user.et,
    };
    this.appService.postIata(params).subscribe((data) => {
      this.listIata = data['iata'];
      // console.log(this.listIata);   
      this.cargando = false;
    });
  }

  getListProveedores() {
    let params = {
      get: true,
      et: this.user.et,
    };
    this.appService.postProveedor(params).subscribe((data) => {
      this.listProveedores = data['obj'];
      // console.log(this.listProveedores);      
      this.cargando = false;
    });
  }

  getListPoliticas() {
    let params = {
      get: true,
      et: this.user.et,
    };
    this.appService.postPoliticas(params).subscribe((data) => {
      this.listPoliticas = data['obj'];
      this.cargando = false;
    });
  }

  getListGaleriaMedios() {
    // console.log(this.user.et);
    this.appService.getGaleriaMedios(this.user.et + '-' + this.pageMedio).subscribe((data) => {
      this.listGaleriaMedios = data['obj'].items;
      this.selectImagen = null;
      // console.log(this.listGaleriaMedios);      
      this.cargando = false;
    });
  }

  getListPoliticasTarjetaReserva(reservaId) {
    let params = {
      getPolitica: true,
      et: this.user.et,
      id: reservaId
    };
    this.appService.postPoliticas(params).subscribe((data) => {
      this.listPoliticasTarjetaReserva = data['obj'];
      this.cargando = false;
    });
  }

  getListCosteoTarjetaReserva(reservaId) {
    let params = {
      getCosteo: true,
      et: this.user.et,
      id: reservaId
    };
    this.appService.postTarjetaReservaCosto(params).subscribe((data) => {
      this.selectCosteo = data['obj'];
      this.sumTotalCosteo = data['objSum'];
      this.cargando = false;
      // console.log(data);      
    });
  }

  addPolitica(item) {
    let params = {
      add: true,
      et: this.user.et,
      id: item.id,
      tarjeta_id: this.tarjeta_reserva_id
    };
    this.appService.postPoliticas(params).subscribe((data) => {
      this.getListPoliticasTarjetaReserva(this.tarjeta_reserva_id);
      this.cargando = false;
    });

  }

  deletePolitica(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar esta Politica?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {

      let params = {
        deletePolitica: true,
        et: this.user.et,
        id: item.id,
        tarjeta_id: this.tarjeta_reserva_id
      };
      this.appService.postPoliticas(params).subscribe((data) => {
        this.getListPoliticasTarjetaReserva(this.tarjeta_reserva_id);
        this.cargando = false;
      });
    });

  }

  getListProveedoresActividades() {
    let params = {
      get: true,
      et: this.user.et,
    };
    this.appService.postProveedorActividad(params).subscribe((data) => {
      this.listProveedoresActividades = data['actividades'];
      this.cargando = false;
    });
  }

  getListHistorialAbonos(reservaId) {
    let params = {
      get: true,
      et: this.user.et,
      id: reservaId
    };
    this.appService.postReservaAbono(params).subscribe((data) => {
      this.listHistorialAbonos = data['tarjeta_reserva_abono'];
      this.abono_reserva = data['abono'];
      this.cargando = false;
    });
  }

  setCrearAbono(crearAbono, reservaId, comprobante, detalle) {
    let params
    if (this.editar_abono) {
      params = {
        editar: true,
        et: this.user.et,
        id: this.abono_id,
        detalle: detalle,
        abono: crearAbono,
        comprobante: comprobante
      };
    } else {
      params = {
        nuevo: true,
        et: this.user.et,
        id: reservaId,
        detalle: detalle,
        abono: crearAbono,
        comprobante: comprobante
      };
    }
    this.appService.postReservaAbono(params).subscribe((data) => {
      this.listHistorialAbonos = data['tarjeta_reserva_abono'];
      this.getListHistorialAbonos(reservaId);
      this.abono_valor = '';
      this.comprobante = '';
      this.comprobante_url = '';
      this.detalle = '';
      this.abono_id = '';
      this.cargando = false;
      this.editar_abono = false;
    });
  }

  abonoEdit(item) {
    this.editar_abono = true;
    this.abono_id = item.id;
    this.detalle = item.detalle;
    this.abono_valor = item.valor;
    this.comprobante_url = item.img_url;
    this.comprobante = item.img;
  }

  abonoDelete(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar este ABONO?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        let params = {
          eliminar: true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postReservaAbono(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getListHistorialAbonos(item.tarjeta_reserva_id);
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });
      }
    });
  }

  getListAdjuntos(reservaId) {
    let params = {
      get: true,
      et: this.user.et,
      id: reservaId
    };
    this.appService.postTarjetaReservaAdjuntos(params).subscribe((data) => {
      this.listAdjuntos = data['obj'];
      // console.log(this.listAdjuntos);
      this.cargando = false;
    });
  }

  adjuntoVisibilidad(id,e){
    let visibilidad
    if(e.target.checked){
      visibilidad = 1;
    }else{
      visibilidad = 0;
    }
    let params = {
      change_estado: true,
      id: id,
      estado: visibilidad,
      et: this.user.et,
    };
    this.appService.postTarjetaReservaAdjuntos(params)
      .subscribe((data) => {
        // console.log(data['obj']);
        if (data["logger"] == true) {
          this.toastr.success(data["msg"]);
          this.getListAdjuntos(this.tarjeta_reserva_id);
        } else {
          this.toastr.error(data["msg"]);
        }
      });

  }

  deleteAdjunto(item) {
    Swal.fire({
      title: '¿Está seguro?',
      html: 'Desea eliminar el archivo <b>' + item.nombre  + ' adjunto</b> ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        let params = {
          eliminar: true,
          et: this.user.et,
          id: item.id
        };
        this.appService.postTarjetaReservaAdjuntos(params).subscribe((data) => {
          // this.listAdjuntos = data['obj'];
          this.cargando = false;
          this.getListAdjuntos(this.tarjeta_reserva_id);
        });

      }
    });
  }

  selectCliente(item) {
    this.buscador = null;
    this.nuevoForm.get('id').setValue(item.id);
    this.nuevoForm.get('nombre').setValue(item.nombre);
    this.nuevoForm.get('cc').setValue(item.cc);
    this.nuevoForm.get('email').setValue(item.email);
    this.nuevoForm.get('direccion').setValue(item.direccion);
    this.nuevoForm.get('celular').setValue(item.celular);
    // console.log(item);
  }


  createCliente(buscador) {
    this.buscador = null;
    $('#crearCliente').show();
  }


  async newCliente() {
    if (this.nuevoClienteForm.valid) {
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        nombre: this.nuevoClienteForm.value.nombre,
        cc: this.nuevoClienteForm.value.cc,
        celular: this.nuevoClienteForm.value.celular,
        direccion: this.nuevoClienteForm.value.direccion,
        email: this.nuevoClienteForm.value.email,
        et: this.user.et,
      };
      this.appService.postClientes(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.nuevoForm.get('id').setValue(data["data"].id);
            this.nuevoForm.get('nombre').setValue(data["data"].nombre);
            this.nuevoForm.get('cc').setValue(data["data"].cc);
            this.nuevoForm.get('email').setValue(data["data"].email);
            this.nuevoForm.get('direccion').setValue(data["data"].direccion);
            this.nuevoForm.get('celular').setValue(data["data"].celular);
            // this.router.navigate(['/']);
            this.nuevoClienteForm.reset()
            $('#crearCliente').hide();
            this.getListClientes();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }


  createPasajero(buscador) {
    this.buscador = null;
    $('#crearPasajero').show();
  }

  async newPasajero() {
    if (this.nuevoPasajeroForm.valid) {

      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        nombre: this.nuevoPasajeroForm.value.nombre,
        cc: this.nuevoPasajeroForm.value.cc,
        adulto: this.nuevoPasajeroForm.value.adulto,
        fecha_nacimiento: this.nuevoPasajeroForm.value.fecha_nacimiento,
        nota: this.nuevoPasajeroForm.value.nota,
        et: this.user.et,
      };
      // console.log(params);
      this.appService.postPasajeros(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            if (this.tarjetaEditar) {
              this.selectPasajero(data["data"]);
            } else {
              this.selectPasajeros.push(data["data"]);
              this.selectPasajerosAcomodacion.push(data["data"]);
            }

            this.nuevoPasajeroForm.reset()
            $('#crearPasajero').hide();
            this.getListPasajeros();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  selectPasajero(item) {
    if (this.tarjetaEditar) {
      let params = {
        nuevo: true,
        id: item.id,
        tarjeta_id: this.tarjeta_reserva_id,
        et: this.user.et,
      };
      this.appService.postTarjetaReservaPasajero(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.buscador = null;
            this.selectPasajeros.push(item);
            this.selectPasajerosAcomodacion.push(item);
            $('#buscarPasajeroModal').modal('hide');
            $('#ver').modal('show');
          } else {
            this.toastr.error(data["msg"]);
          }
        });

    } else {
      this.buscador = null;
      this.selectPasajeros.push(item);
      this.selectPasajerosAcomodacion.push(item);
      $('#buscarPasajeroModal').modal('hide');
      $('#nuevo').modal('show');

    }

  }

  selectEditarPasajero(item){
    this.editPasajeroForm.get('id').setValue(item.pasajero_id);
    this.editPasajeroForm.get('nombre').setValue(item.nombre);
    this.editPasajeroForm.get('cc').setValue(item.cc);
    this.editPasajeroForm.get('adulto').setValue(item.adulto);
    this.editPasajeroForm.get('fecha_nacimiento').setValue(item.fecha_nacimiento);
    this.editPasajeroForm.get('nota').setValue(item.nota);
    $('#ver').modal('hide');
    $('#editarPasajeroModal').modal('show');
  }

  async editPasajero() {
    if (this.editPasajeroForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.editPasajeroForm.value.id,
        nombre: this.editPasajeroForm.value.nombre,
        cc: this.editPasajeroForm.value.cc,
        adulto: this.editPasajeroForm.value.adulto,
        fecha_nacimiento: this.editPasajeroForm.value.fecha_nacimiento,
        nota: this.editPasajeroForm.value.nota,
        et: this.user.et,
      };
      // console.log(params);
      this.appService.postPasajeros(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.toastr.success(data["msg"]);                 

            let params = {
              get: true,
              tarjeta_id: this.tarjeta_reserva_id,
              et: this.user.et,
            };
            this.appService.postTarjetaReservaPasajero(params)
              .subscribe((data) => {
                if (data["logger"] == true) {
                  this.selectPasajeros = data["obj"]; 
                } else {
                  this.toastr.error(data["msg"]);
                }
              });

            this.editPasajeroForm.reset()
            $('#editarPasajeroModal').modal('hide');
            $('#ver').modal('show');

          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }

  }


  selectVueloOrigen(item) {
    this.nuevoVueloForm.get('origen').setValue(item.codigo);
    $('#iataOrigen').hide();
  }

  selectVueloDestino(item) {
    this.nuevoVueloForm.get('destino').setValue(item.codigo);
    $('#iataDestino').hide();
  }

  async newVuelo() {
    if (this.nuevoVueloForm.valid) {

      if (this.tarjetaEditar) {
        this.isAuthLoading = true;
        let params = {
          nuevo: true,
          id: this.tarjeta_reserva_id,
          campos: this.nuevoVueloForm.value,
          et: this.user.et,
        };
        this.appService.postTarjetaReservaVuelo(params)
          .subscribe((data) => {
            if (data["logger"] == true) {              
              this.selectVuelos.push(this.nuevoVueloForm.value);
              this.nuevoVueloForm.reset()
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      } else {
        this.selectVuelos.push(this.nuevoVueloForm.value);
        this.nuevoVueloForm.reset()
      }

      this.isAuthLoading = false;

    } else {
      this.toastr.error('El formulario no es válido!');
    }

  }

  createCosteo() {
    this.nuevoVueloForm.reset()
    if (this.tarjetaEditar) {
      $('#editCosteo').show();
    } else {
      $('#crearCosteo').show();
    }
  }

  async newCosteo() {
    if (this.nuevoCosteoForm.valid) {
      if (this.tarjetaEditar) {
        this.isAuthLoading = true;
        let params = {
          nuevo: true,
          id: this.tarjeta_reserva_id,
          campos: this.nuevoCosteoForm.value,
          et: this.user.et,
        };
        this.appService.postTarjetaReservaCosto(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              // this.selectCosteo.push(this.nuevoCosteoForm.value);
              // this.sumTotalCosteo = data['costeo_total'];
              // this.getSumTotalCosteo();

              this.getListCosteoTarjetaReserva(this.tarjeta_reserva_id);
              this.nuevoCosteoForm.reset()
              this.getList();
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      } else {
        this.getListCosteoTarjetaReserva(this.tarjeta_reserva_id);
        // this.selectCosteo.push(this.nuevoCosteoForm.value);
        // this.getSumTotalCosteo();
        this.nuevoCosteoForm.reset()
      }

      this.isAuthLoading = false;

    } else {
      this.toastr.error('El formulario no es válido!');
    }


  }


  getSumTotalCosteo() {
    this.sumTotalCosteo = 0;
    this.selectCosteo.map((item, index) => {
      let producto = item.valor * item.cantidad;
      this.sumTotalCosteo += producto;
    })
  }

  deleteCosteo(i, item) {
    Swal.fire({
      title: '¿Está seguro?',
      html: 'Desea eliminar <b>' + item.valor * item.cantidad + '</b> del Costeo?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // this.selectCosteo.splice(i, 1);
        // let producto = item.valor * item.cantidad;
        // this.sumTotalCosteo -= producto;

        let params = {
          deleteCosteo: true,
          et: this.user.et,
          id: item.id,
          tarjeta_id: this.tarjeta_reserva_id
        };
        this.appService.postTarjetaReservaCosto(params).subscribe((data) => {
          this.getListCosteoTarjetaReserva(this.tarjeta_reserva_id);
          this.cargando = false;
        });

      }
    });
  }

  selectProveedor(item) {
    this.buscador = null;
    let proveedorId = item.id;
    if (proveedorId) {
      $('#proveedorList').hide();

      let params = {
        get: true,
        et: this.user.et,
        id: proveedorId
      };
      this.appService.postProveedorActividad(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.listProveedoresActividades = data["obj"];
            $('#proveedorActividadList').show();
          } else {
            this.toastr.error(data["msg"]);
          }
        });

    }
    // this.selectProveedorActividades.push(item);
  }

  selectProveedorActividad(item) {
    if (this.tarjetaEditar) {
      let params = {
        nuevo: true,
        id: item.id,
        tarjeta_id: this.tarjeta_reserva_id,
        et: this.user.et,
      };
      // console.log(params);
      this.appService.postTarjetaReservaActividad(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.buscador = null;
            // console.log(item);
            // this.selectProveedorActividades.push(item);
            this.selectProveedorActividades.push(data["data"]);
            // console.log(data["data"]);

            $('#proveedorList').show();
            $('#proveedorActividadList').hide();
            $('#buscarActividadModal').modal('hide');
            $('#ver').modal('show');
          } else {
            this.toastr.error(data["msg"]);
          }
        });

    } else {
      this.buscador = null;
      this.selectProveedorActividades.push(item);
      $('#proveedorList').show();
      $('#proveedorActividadList').hide();
      $('#buscarActividadModal').modal('hide');
      $('#nuevo').modal('show');

    }

  }

  atrasBuscarProveedor() {
    $('#proveedorList').show();
    $('#proveedorActividadList').hide();
  }

  deletePasajeros(i, item) {
    // console.log(item);
    Swal.fire({
      title: '¿Está seguro?',
      html: 'Desea eliminar <b>' + item.nombre + '</b> como Pasajero, la acomodación se eliminará?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log(item);
        if (this.tarjetaEditar) {
          let params = {
            eliminar: true,
            id: item.id,
            tarjeta_id: this.tarjeta_reserva_id,
            et: this.user.et,
          };
          // console.log(params);
          this.appService.postTarjetaReservaPasajero(params)
            .subscribe((data) => {
              if (data["logger"] == true) {
                this.selectPasajeros.splice(i, 1);
                this.selectPasajerosAcomodacion.splice(i, 1);
                this.selectCAcomodacion = [];
              } else {
                this.toastr.error(data["msg"]);
              }
            });

        } else {
          this.selectPasajeros.splice(i, 1);
          this.selectPasajerosAcomodacion.splice(i, 1);
        }

      }
    });



  }

  deleteVuelos(i, item) {
    Swal.fire({
      title: '¿Está seguro?',
      html: 'Desea eliminar el vuelo <b>' + item.vuelo + '</b>?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {

        if (this.tarjetaEditar) {
          let params = {
            eliminar: true,
            id: item.id,
            tarjeta_id: this.tarjeta_reserva_id,
            et: this.user.et,
          };
          // console.log(params);
          this.appService.postTarjetaReservaVuelo(params)
            .subscribe((data) => {
              if (data["logger"] == true) {
                this.selectVuelos.splice(i, 1);
              } else {
                this.toastr.error(data["msg"]);
              }
            });

        } else {
          this.selectVuelos.splice(i, 1);
        }

      }
    });
  }

  deleteProveedorActividades(i, item) {
    // console.log(item);
    Swal.fire({
      title: '¿Está seguro?',
      html: 'Desea eliminar el proveedor <b>' + item.proveedor + '</b>?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        if (this.tarjetaEditar) {
          let params = {
            eliminar: true,
            id: item.id,
            tarjeta_id: this.tarjeta_reserva_id,
            et: this.user.et,
          };
          // console.log(params);
          this.appService.postTarjetaReservaActividad(params)
            .subscribe((data) => {
              if (data["logger"] == true) {
                this.selectProveedorActividades.splice(i, 1);
              } else {
                this.toastr.error(data["msg"]);
              }
            });

        } else {
          this.selectProveedorActividades.splice(i, 1);
        }

      }
    });

  }

  changePage(page) {
    this.page = page; //para el filtro
    this.tarjeta_reserva.current = page;
    this.getList();
  }

  ForpagesNumber() {
    let from = this.tarjeta_reserva.current - this.offset;
    if (from < 1) {
      from = 1;
    }

    let to = from + (this.offset * 2);
    if (to >= this.tarjeta_reserva.total_page) {
      to = this.tarjeta_reserva.total_page;
    }

    let pagesArray = [];
    while (from <= to) {
      pagesArray.push(from);
      from++;
    }
    this.pagesNumber = pagesArray;
    // return pagesArray;
  }

  async nuevoTarjeta() {
    if (this.nuevoForm.valid) {
      this.cargando = true;
      this.isAuthLoading = true;
      let params = {
        nuevo: true,
        et: this.user.et,
        ut: this.user.token,
        id: this.nuevoForm.value.id,
        // tarjeta: {
        //   'contacto_nombre': this.contacto_nombre,
        //   'contacto_celular': this.contacto_celular,
        //   'referido': this.referido,
        //   'hotel': this.hotel,
        //   'fecha_llegada': this.fecha_llegada,
        //   'fecha_out': this.fecha_out,
        //   'adulto': this.adulto,
        //   'adulto_tarifa': this.adulto_tarifa,
        //   'nino': this.nino,
        //   'nino_tarifa': this.nino_tarifa,
        //   'infante': this.infante,
        //   'infante_tarifa': this.infante_tarifa,
        //   'total_venta': this.adulto_tarifa * this.adulto + this.nino_tarifa * this.nino + this.infante_tarifa * this.infante,
        // },
        // pasajeros: this.selectPasajeros,
        // vuelos: this.selectVuelos,
        // proveedores: this.selectProveedorActividades,
        // acomodacion: this.selectCAcomodacion,
        // costeo: this.selectCosteo,
        // total_costeo: this.sumTotalCosteo,
        estado: 1,
      };
      // console.log(params);
      this.appService.postTarjetaReserva(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            // console.log(this.nuevoForm.value);
            let params = {
              id: data["data"].id,
              cliente_id: this.nuevoForm.value.cliente_id,
              nombre: this.nuevoForm.value.nombre,
              email: this.nuevoForm.value.email,
              cc: this.nuevoForm.value.cc,
              direccion: this.nuevoForm.value.direccion,
              celular: this.nuevoForm.value.celular,
              ciudad: this.nuevoForm.value.ciudad,
            }
            this.ver(params);
            this.nuevoForm.reset()
            $('#nuevo').modal('hide');
            $('#ver').modal('show');

            this.toastr.success(data["msg"]);
            this.selectPasajeros = [];
            this.selectPasajerosAcomodacion = [];
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
      this.cargando = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  ver(item) {
    // console.log(item);
    this.contacto_nombre
    this.tarjetaEditar = true;
    this.selectPasajeros = null;
    this.selectVuelos = null;
    this.editForm.get('id').setValue(item.cliente_id);
    this.editForm.get('nombre').setValue(item.nombre);
    this.editForm.get('email').setValue(item.email);
    this.editForm.get('cc').setValue(item.cc);
    this.editForm.get('direccion').setValue(item.direccion);
    this.editForm.get('celular').setValue(item.celular);
    this.editForm.get('ciudad').setValue(item.ciudad);

    this.tarjeta_reserva_id = item.id;
    this.contacto_nombre = item.contacto_nombre;    
    this.contacto_nombre_borrador = item.contacto_nombre;    

    this.contacto_celular = item.contacto_celular;
    this.contacto_celular_borrador = item.contacto_celular;

    this.referido = item.referido_usuario_id;
    this.hotel = item.hotel;
    this.hotel_borrador = item.hotel;
    this.relacion_hoteles = item.relacion_hoteles;
    this.relacion_hoteles_borrador = item.relacion_hoteles;
    this.fecha_llegada = item.fecha_llegada;
    this.fecha_llegada_borrador = item.fecha_llegada;
    this.fecha_out = item.fecha_out;
    this.fecha_out_borrador = item.fecha_out;
    this.noches = item.noches;

    this.adulto = item.adulto;
    this.adulto_borrador = item.adulto;
    this.adulto_tarifa = item.adulto_tarifa;
    this.adulto_tarifa_borrador = item.adulto_tarifa;
    this.nino = item.nino;
    this.nino_borrador = item.nino;
    this.nino_tarifa = item.nino_tarifa;
    this.nino_tarifa_borrador = item.nino_tarifa;
    this.infante = item.infante;
    this.infante_borrador = item.infante;
    this.infante_tarifa = item.infante_tarifa;
    this.infante_tarifa_borrador = item.infante_tarifa;
    this.sumTotalCosteo = item.costeo_total;
    this.total_venta = item.total_venta;
    this.img = item.img;
    this.img_archivo_url = item.archivo_url;

    this.selectPasajeros = item.pasajeros;
    this.selectVuelos = item.vuelos;
    this.selectProveedorActividades = item.proveedores;
    this.selectCosteo = item.costeo;
    this.selectCAcomodacion = item.acomodacion;

    $('#ver').modal('show');
    this.getListHistorialAbonos(item.id);
    this.getListAdjuntos(item.id);
    this.getListPoliticasTarjetaReserva(item.id);
    this.calDias(item.fecha_llegada, item.fecha_out);
  }

  async editUsuario() {
    if (this.editForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editar: true,
        id: this.editForm.value.id,
        nombre: this.editForm.value.nombre,
        email: this.editForm.value.email,
        direccion: this.editForm.value.direccion,
        celular: this.editForm.value.celular,
        cc: this.editForm.value.cc,
        password: this.editForm.value.password,
        nota: this.editForm.value.nota,
        empresa_id: this.user.eid
      };
      // console.log(params);
      this.appService.postClientes(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            this.toastr.success(data["msg"]);
            this.editForm.reset()
            $('#ver').modal('hide');
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  async eliminarTarjeta(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar Tarjeta de Reserva?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        // console.log(item);

        let params = {
          eliminar: true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postTarjetaReserva(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getList();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });
  }
  async ocultarTarjeta(item) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea ocultar la tarjeta de reserva?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {
      if (result.value) {
        let params = {
          ocultar: true,
          id: item.id,
          empresa_id: this.user.eid
        }
        this.appService.postTarjetaReserva(params)
          .subscribe((data) => {
            if (data["logger"] == true) {
              this.getList();
              this.toastr.success(data["msg"]);
            } else {
              this.toastr.error(data["msg"]);
            }
          });

      }
    });
  }


  async editarTarjeta() {
    if (this.editForm.valid) {
      // console.log(this.editForm.value);
      this.isAuthLoading = true;
      let params = {
        editar: true,
        et: this.user.et,
        id: this.tarjeta_reserva_id,
        tarjeta: {
          'cliente_id': this.editForm.value.id,
          'contacto_nombre': this.contacto_nombre,
          'contacto_celular': this.contacto_celular,
          'referido': this.referido,
          'hotel': this.hotel,
          'relacion_hoteles': this.relacion_hoteles,
          'fecha_llegada': this.fecha_llegada,
          'fecha_out': this.fecha_out,
          'adulto': this.adulto,
          'adulto_tarifa': this.adulto_tarifa,
          'nino': this.nino,
          'nino_tarifa': this.nino_tarifa,
          'infante': this.infante,
          'infante_tarifa': this.infante_tarifa,
          'total_venta': this.adulto_tarifa * this.adulto + this.nino_tarifa * this.nino + this.infante_tarifa * this.infante,
          'img': this.img,
        },
        pasajeros: this.selectPasajeros,
        vuelos: this.selectVuelos,
        proveedores: this.selectProveedorActividades,
        acomodacion: this.selectCAcomodacion,
        costeo: this.selectCosteo,
        total_costeo: this.sumTotalCosteo,
        estado: 1,
      };
      // console.log(params);
      this.appService.postTarjetaReserva(params)
        .subscribe((data) => {
          // console.log(data);
          if (data["logger"] == true) {
            // this.nuevoForm.reset()
            // $('#nuevo').modal('hide');
            this.contacto_nombre_borrador = this.contacto_nombre;
            this.contacto_celular_borrador = this.contacto_celular;
            this.adulto_tarifa_borrador = this.adulto_tarifa;
            this.nino_tarifa_borrador = this.nino_tarifa;
            this.infante_tarifa_borrador = this.infante_tarifa;

            this.hotel_borrador = this.hotel;
            this.fecha_llegada_borrador = this.fecha_llegada;
            this.fecha_out_borrador = this.fecha_out;
            this.relacion_hoteles_borrador = this.relacion_hoteles;

            this.toastr.success(data["msg"]);
            // this.selectPasajeros = [];
            // this.selectPasajerosAcomodacion = [];
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  async actualizarTarjetaAcomodacion() {
    if (this.editForm.valid) {
      this.isAuthLoading = true;
      let params = {
        editarAcomodacion: true,
        et: this.user.et,
        id: this.tarjeta_reserva_id,
        acomodacion: this.selectCAcomodacion,
        estado: 1,
      };
      this.appService.postTarjetaReserva(params)
        .subscribe((data) => {
          if (data["logger"] == true) {
            // this.toastr.success(data["msg"]);
            this.getList();
          } else {
            this.toastr.error(data["msg"]);
          }
        });
      this.isAuthLoading = false;
    } else {
      this.toastr.error('El formulario no es válido!');
    }
  }

  calDias(llegada, salida) {
    const fecha_inicio = moment(llegada);
    const fecha_termino = moment(salida);
    let dias = fecha_termino.diff(fecha_inicio, 'days');
    if (dias) {
      this.noches = dias;
    }
  }

  addAcomodacion(i) {
    // console.log(i);
    if (!this.selectCAcomodacion) {
      this.selectCAcomodacion = [];
      this.selectCAcomodacion.push({ 'tipo': i });
    } else {
      this.selectCAcomodacion.push({ 'tipo': i });
    }
    // console.log(this.selectCAcomodacion);
  }

  addPasajeroAcomodacion(tipo, i) {
    this.tipoAcomodacion = tipo;
    this.tipoAcomodacionI = i;

    this.selectPasajerosAcomodacion = [];
    this.selectPasajeros.forEach(item => {
      // console.log(item.id);
      this.selectPasajerosAcomodacion.push(item);
    });

    this.getPasajerosDisponibles();
    // console.log(this.tarjeta_reserva_id);
    $('#pasajerosDisponibles').modal('show');
    // $('#newAcomodacion').show();
  }

  getPasajerosDisponibles() {
    let params = {
      getPasajerosAcomodacion: true,
      id: this.tarjeta_reserva_id,
      et: this.user.et,
    };
    this.appService.postTarjetaReserva(params)
      .subscribe((data) => {
        // console.log(data['obj']);
        if (data["logger"] == true) {
          this.selectPasajerosDisponibleAcomodacion = data['obj'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  selectPasajeroAcomodacion(itemPasajero, i, tipoAcomodacionI) {
    // console.log(itemPasajero);
    let params = {
      editPasajerosAcomodacion: true,
      id: itemPasajero.id,
      tarjeta_id: this.tarjeta_reserva_id,
      estado: 1,
      et: this.user.et,
    };
    this.appService.postTarjetaReservaPasajero(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.selectCAcomodacion.map((item, index) => {
            if (index == this.tipoAcomodacionI) {
              if (item.pasajeros == null) {
                item.pasajeros = [itemPasajero]
              } else {
                item.pasajeros.push(itemPasajero)
              }
            }
          })
          // this.selectCAcomodacionGeneral.push(itemPasajero);
          // console.log(this.selectCAcomodacionGeneral);
          this.selectPasajerosDisponibleAcomodacion.splice(i, 1);
          this.actualizarTarjetaAcomodacion();

        } else {
          this.toastr.error(data["msg"]);
        }
      });



  }

  deleteTipoAcomodacion(i) {
    // console.log(this.selectCAcomodacion);
    this.selectCAcomodacion.splice(i, 1);
    this.actualizarTarjetaAcomodacion();
  }

  deletePasajeroAcomodacion(i, iP, pasajero_id) {
    // console.log(i);
    // console.log(iP);
    // console.log(pasajero_id);

    let params = {
      editPasajerosAcomodacion: true,
      id: pasajero_id,
      tarjeta_id: this.tarjeta_reserva_id,
      estado: null,
      et: this.user.et,
    };
    this.appService.postTarjetaReservaPasajero(params)
      .subscribe((data) => {
        if (data["logger"] == true) {

          // console.log(this.selectCAcomodacion);
          this.selectCAcomodacion.map((item, index) => {
            if (index == i) {
              // console.log(item.pasajeros);        
              if (item.pasajeros == null) {
                console.log('null');
                // item.pasajeros = [itemPasajero]
              } else {
                console.log('splice');
                item.pasajeros.splice(iP, 1)
              }
            }
          })

          this.actualizarTarjetaAcomodacion();

        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }


  async findTarjeta() {
    let params = {
      buscar: true,
      buscar_input: this.findInput,
      page: this.page,
      et: this.user.et,
    };
    this.appService.postTarjetaReserva(params)
      .subscribe((data) => {
        if (data["logger"] == true) {
          this.tarjeta_reserva = data['tarjeta_reserva'];
        } else {
          this.toastr.error(data["msg"]);
        }
      });
  }

  uploadImg(files, componente) {
    if (files.length === 0)
      return;
    this.loadingImg = true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      const imgURL = reader.result;

      if (!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: 'comprobante',
          archivo: imgURL
        };
        // this.cargandoImg = true;
        this.appService.uploadImg(params)
          .subscribe((data) => {
            // console.log(data);

            if (data['logger'] == true) {
              if (componente == 'nuevo') {
                console.log('nuevo');

                this.comprobante = data['filename'];
                this.comprobante_url = data['fileUrl'];
              }
              if (componente == 'editar') {
                console.log('edit');

                this.comprobante = data['filename'];
                this.comprobante_url = data['fileUrl'];
              }

            }
            this.loadingImg = false;
          });
      }
    }
  }

  selectImagenApariencia(item){
    // console.log(item);
    this.img = item.nombre;
    this.img_archivo_url = item.archivo_url;
    this.editarTarjeta();
  }

  verImagenSelect(item){
    // console.log(item);
    this.selectImagen = item;
  }

  deleteImagenSelect(item){
    Swal.fire({
      title: '¿Está seguro?',
      text: `Desea eliminar esta imagen?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, estoy seguro!',
    }).then((result) => {

      let params = {
        eliminar: true,
        et: this.user.et,
        id: item.id,
      };
      this.appService.postGaleriaMedios(params).subscribe((data) => {
        this.getListGaleriaMedios();
        this.cargando = false;
      });
    });
  }

  uploadImgGaleriaMedios(files) {
    if (files.length === 0)
      return;
    this.loadingImg = true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      const imgURL = reader.result;

      if (!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: 'galeria-medios',
          archivo: imgURL
        };
        // this.cargandoImg = true;
        this.appService.uploadImg(params)
          .subscribe((data) => {
            // console.log(data);
            if (data['logger'] == true) {

              let params = {
                nuevo: true,
                et: this.user.et,
                nombre: data['filename'],
                archivo: data['filename'],
              };
              // this.cargandoImg = true;
              this.appService.postGaleriaMedios(params)
                .subscribe((data) => {
                  // console.log(data);
    
                  if (data['logger'] == true) { 
                    this.getListGaleriaMedios();     
                  }
                });              

            }
            this.loadingImg = false;
          });
      }
    }
  }

  uploadArchivo(files) {
    if (files.length === 0)
      return;
    this.loadingImg = true;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      const imgURL = reader.result;

      if (!imgURL) {
        console.log('error');
        return;
      } else {
        let params = {
          folder: 'adjuntos',
          archivo: imgURL
        };
        this.appService.uploadImg(params)
          .subscribe((data) => {
            // console.log(data);

            if (data['logger'] == true) {
              this.adjunto_archivo = data['filename'];
              this.adjunto_archivo_url = data['fileUrl'];
              this.adjunto_archivo_extension = data['extension'];
              this.cargarArchivo();
            }
            this.loadingImg = false;
          });
      }
    }
  }


  cargarArchivo() {
    let params = {
      nuevo: true,
      et: this.user.et,
      id: this.tarjeta_reserva_id,
      nombre: this.adjunto_nombre,
      adjunto: this.adjunto_archivo,
      extension: this.adjunto_archivo_extension
    };
    this.appService.postTarjetaReservaAdjuntos(params).subscribe((data) => {
      this.listAdjuntos = data['obj'];
      this.getListAdjuntos(this.tarjeta_reserva_id);
      this.adjunto_archivo = '';
      this.adjunto_nombre = '';
      this.adjunto_archivo_url = '';
      this.adjunto_archivo_extension = '';
      this.cargando = false;
    });
  }


}
