import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plantillas',
  templateUrl: './plantillas.component.html',
  styleUrls: ['./plantillas.component.scss']
})
export class PlantillasComponent implements OnInit {
  // Elementos disponibles para arrastrar
  elements = [
    { id: 1, type: 'button', label: 'Botón Primario', class: 'btn btn-primary' },
    { id: 2, type: 'text', label: 'Texto de ejemplo', class: 'text-muted' },
    { id: 3, type: 'image', label: 'Imagen', src: 'assets/img/img-default.png' },
    { id: 4, type: 'grid-box', label: 'Grid Box', columns: 2, items: [] } // Grid Box con capacidad de dividirse
  ];

  // Elementos agregados a la zona de construcción
  droppedItems: any[] = [];
  draggedItem: any = null;
  isNewElement: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  onDragStart(event: DragEvent, item: any, index: number, isNew: boolean = false) {
    this.draggedItem = { item, index };
    this.isNewElement = isNew;
    event.dataTransfer?.setData('text', JSON.stringify(this.draggedItem));
  }

  allowDrop(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent, dropIndex: number, parentItem: any = null) {
    event.preventDefault();

    if (this.draggedItem && this.draggedItem.item) {
      const { item, index } = this.draggedItem;

      // Verificar si el elemento es nuevo o si ya existe en la zona de construcción
      if (this.isNewElement) {
        // Si es un nuevo elemento desde el panel, añadir una copia del elemento
        const newItem = { ...item, id: Date.now(), items: [], columns: item.columns || 1 };
        if (parentItem && parentItem.type === 'grid-box') {
          parentItem.items.push(newItem);
        } else {
          this.droppedItems.splice(dropIndex, 0, newItem);
        }
      } else {
        // Si es un elemento existente, moverlo dentro de la zona de construcción
        if (parentItem && parentItem.type === 'grid-box') {
          parentItem.items.splice(index, 1);
          parentItem.items.splice(dropIndex, 0, item);
        } else {
          this.droppedItems.splice(index, 1);
          this.droppedItems.splice(dropIndex, 0, item);
        }
      }
    }

    // Resetear el estado
    this.draggedItem = null;
    this.isNewElement = false;
  }

  addElementToZone(item: any) {
    const newItem = { ...item, id: Date.now(), items: [], columns: 1 };
    this.droppedItems.push(newItem);
  }

  // Método para eliminar elementos
  removeItem(index: number, parentItem: any = null) {
    if (parentItem) {
      parentItem.items.splice(index, 1);
    } else {
      this.droppedItems.splice(index, 1);
    }
  }

  // Cambiar el número de columnas del Grid Box
  setGridColumns(item: any, columns: number) {
    item.columns = columns;
  }
}
