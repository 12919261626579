import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string | null {
    if (value == null || isNaN(value)) {
      return '0'; // Devuelve '0' si el valor es null, undefined o no numérico
    }
    
    let num = value.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
    num = num.split('').reverse().join('').replace(/^[\.]/,'');
    return num;
  }

}
