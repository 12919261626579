<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-12">
            <h1>
              Visas
              <button class="btn btn-sm btn-success " type="button" data-bs-toggle="modal" data-bs-target="#sendformato"
                aria-controls="sendformato" (click)="selectFormato = null"><i class="fa fa-paper-plane"></i> </button>
              <button class="btn btn-sm btn-primary ml-1" type="button" data-bs-toggle="modal"
                data-bs-target="#verformato"><i class="fa fa-passport"></i> Formatos</button>
              <button class="btn btn-sm btn-primary ml-1" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNuevaSolicitud" aria-controls="offcanvasNuevaSolicitud"
                (click)="selectFormato = null"><i class="fa fa-plus"></i> Nueva Solicitud</button>
            </h1>
          </div>

        </div>

      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">

          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Visas</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Modal sendFormato -->
<div class="modal fade" id="sendformato" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Enviar Formato Whatsapp: </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-4" *ngFor="let item of listFormato">
            <img class="img-fluid img-thumbnail" src="{{item.img_url}}" style="max-width: 80px;cursor: pointer;"
              (click)="selectFormatoSend(item)">
          </div>
          <div class="col-12 mt-3">
            <h4>{{selectFormato?.nombre | titlecase}}</h4>
          </div>
          <div class="col-8">
            <input type="number" class="form-control input-lg" [(ngModel)]="whatsappNumero"
              placeholder="Número whatsapp">
          </div>

          <div class="col-4">
            <!-- Botón con enlace dinámico que se habilita solo si whatsappNumero y selectFormato?.id existen -->
            <a class="btn btn-block btn-success text-center"
              [href]="whatsappNumero && selectFormato?.id ? 'https://wa.me/57' + whatsappNumero + '?text=https://aplicacion.faroapp.com.co/visa-formato/' + selectFormato?.id : '#'"
              [attr.disabled]="!(whatsappNumero && selectFormato?.id) ? true : null"
              [class.disabled]="!(whatsappNumero && selectFormato?.id)" target="_blank">
              <i class="fa fa-paper-plane" style="color:white"></i>
            </a>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>

<!-- Modal VerFormato -->
<div class="modal fade" id="verformato" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Formatos Visas <button type="button"
            class="btn btn-sm btn-outline-dark" data-bs-toggle="modal" data-bs-target="#crearformato"><i
              class="fa fa-file-text"></i> Crear formato visa</button></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">

        <table class="table table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th></th>
              <th scope="col">ACCIONES</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listFormato">
              <th scope="row"><img class="img-fluid img-thumbnail" src="{{item.img_url}}"
                  style="max-width: 120px;cursor: pointer;" (click)="ver(item)">
              </th>
              <td>
                <div><b style="font-size: 1.3em;" style="cursor: pointer;" (click)="ver(item)">{{item.nombre |
                    titlecase}}</b> </div>
                <div style="cursor: pointer;" (click)="ver(item)">COSTO CONSULARES <b>{{item.costo}}</b> </div>
                <div style="cursor: pointer;" (click)="ver(item)">COSTO SERVICIO <b>{{item.costo_servicio}}</b> </div>
              </td>
              <td>
                <div class="dropdown">
                  <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false"></button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><span class="dropdown-item" style="cursor: pointer;" (click)="ver(item)">Ver formato</span></li>
                    <li><span class="dropdown-item">
                        <a href="https://wa.me/57{{whatsappNumero}}?text=https://aplicacion.faroapp.com.co/visa-formato/{{item.id}}"
                          target="_blank" style="color: #000;">Enviar formato Whatsapp</a></span></li>
                    <li *ngIf="user.perfil =='2fk6i2o3143'"><span class="dropdown-item"
                        style="color: red; cursor: pointer;" (click)="deleteFormato(item.id)"><i
                          class="fa fa-trash"></i> Eliminar Formato visa</span></li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<!-- Modal crear Formato -->
<div class="modal fade" id="crearformato" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Crear Formato Visa</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal"
          data-bs-target="#verformato" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="nuevoForm" (ngSubmit)="nuevoFormato()">
          <div class="row">

            <div class="row">
              <div class="col-4">
                <div class="text-center">
                  <div *ngIf="nuevoForm.value.img" class="user-image img-circle elevation-2 img-fluid"
                    (click)="fileEditar.click()"
                    [ngStyle]="{'background-image':' url(' + nuevoForm.value.img_url || 'assets/img/bandera-default.png' + ')','height':'150px','width':'150px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right': 'auto'}">
                  </div>

                  <div *ngIf="!nuevoForm.value.img" class="user-image img-circle elevation-2 img-fluid"
                    (click)="fileEditar.click()"
                    [ngStyle]="{'background-image':' url(' + 'assets/img/bandera-default.png' + ')','height':'150px','width':'150px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right': 'auto'}">
                  </div>

                  <div *ngIf="loadingImg" class="spinner-border text-secondary"
                    style="width: 3rem; height: 3rem;position: absolute;left: 48%;top: 35%;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <input #fileEditar type="file" accept='image/*' (change)="preview(fileEditar.files,'editar')"
                    style="display: none" />
                </div>
              </div>
              <div class="col-8">
                <div class="col-12">
                  <div class="form-floating mb-3">
                    <input formControlName="nombre" type="text" class="form-control" placeholder=">Nombre">
                    <label>Nombre</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-floating mb-3">
                    <input formControlName="costo" type="text" class="form-control" placeholder=">costo">
                    <label>Costos Consulares</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-floating mb-3">
                    <input formControlName="costo_servicio" type="text" class="form-control"
                      placeholder=">costo_servicio">
                    <label>Costo Servicio</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="form-floating mb-3">
                <textarea class="form-control" formControlName="requisitos" placeholder="requisitos"
                  style="height: 190px"></textarea>
                <label>Requisitos</label>
              </div>
            </div>
            <div class="col-12">
              <button class="btn btn-lg btn-block btn-primary" type="submit">Guardar</button>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<!-- Modal ver / editar Formato -->
<div class="modal fade" id="editarformato" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Editar Formato Visa</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="editForm" (ngSubmit)="editFormato()">
          <div class="row">
            <div class="row">
              <div class="col-4">
                <div class="text-center">
                  <div *ngIf="editForm.value.img" class="user-image img-thumbnail elevation-2 img-fluid"
                    (click)="fileEditar.click()"
                    [ngStyle]="{'background-image':' url(' + editForm.value.img_url || 'assets/img/bandera-default.png' + ')','height':'150px','width':'150px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right': 'auto'}">
                  </div>

                  <div *ngIf="!editForm.value.img" class="user-image img-thumbnail elevation-2 img-fluid"
                    (click)="fileEditar.click()"
                    [ngStyle]="{'background-image':' url(' + 'assets/img/bandera-default.png' + ')','height':'150px','width':'150px','background-size':'cover','background-position':'center','cursor':'pointer','margin-left':'auto','margin-right': 'auto'}">
                  </div>

                  <div *ngIf="loadingImg" class="spinner-border text-secondary"
                    style="width: 3rem; height: 3rem;position: absolute;left: 48%;top: 35%;" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <input #fileEditar type="file" accept='image/*' (change)="preview(fileEditar.files,'editar')"
                    style="display: none" />
                </div>
              </div>
              <div class="col-8">
                <div class="col-12">
                  <div class="form-floating mb-3">
                    <input formControlName="nombre" type="text" class="form-control" placeholder=">Nombre">
                    <label>Nombre</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-floating mb-3">
                    <input formControlName="costo" type="text" class="form-control" placeholder=">costo">
                    <label>Costos Consulares</label>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-floating mb-3">
                    <input formControlName="costo_servicio" type="text" class="form-control"
                      placeholder=">costo_servicio">
                    <label>Costo Servicio</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="container">
              <!-- Nav tabs -->
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="requisitos-tab" data-bs-toggle="tab" data-bs-target="#requisitos"
                    type="button" role="tab" aria-controls="requisitos" aria-selected="true">Requisitos</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="entrevista-tab" data-bs-toggle="tab" data-bs-target="#entrevista"
                    type="button" role="tab" aria-controls="entrevista" aria-selected="false">Entrevista</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="adjunto-tab" data-bs-toggle="tab" data-bs-target="#adjunto" type="button"
                    role="tab" aria-controls="adjunto" aria-selected="false">Adjunto</button>
                </li>
              </ul>

              <!-- Tab content -->
              <div class="tab-content" id="myTabContent">
                <!-- Requisitos Tab -->
                <div class="tab-pane fade show active" id="requisitos" role="tabpanel" aria-labelledby="requisitos-tab">
                  <div class="form-floating mb-3">
                    <!-- <h4>Requisitos</h4> -->
                    <quill-editor formControlName="requisitos" placeholder="Escribe los requisitos"
                      style="height: 190px"></quill-editor>
                  </div>
                </div>

                <!-- Entrevista Tab -->
                <div class="tab-pane fade" id="entrevista" role="tabpanel" aria-labelledby="entrevista-tab">
                  <div class="form-floating mb-3">
                    <!-- <h4>Entrevista</h4> -->
                    <quill-editor formControlName="entrevista" placeholder="Escribe la entrevista"
                      style="height: 190px"></quill-editor>
                  </div>
                </div>

                <!-- Adjunto Tab -->
                <div class="tab-pane fade" id="adjunto" role="tabpanel" aria-labelledby="adjunto-tab">
                  <div class="form-floating mb-3">
                    <h4 class="mt-5">Adjunto</h4>

                    <div class="row">
                      <div class="col-4">
                        <input #fileUploadAdjuntosFormato type="file" class="form-control mb-5"
                          (change)="uploadArchivoFormato(fileUploadAdjuntosFormato.files)"
                          accept='application/pdf, image/*'>
                      </div>
                      <div class="col-8">
                        <div class="list-group">
                          <!-- Mostrar mensaje si no hay adjuntos -->
                          <div *ngIf="!editForm.get('formulario')?.value" class="alert alert-primary" role="alert">
                            No tiene archivo adjunto en el formulario.
                          </div>

                          <!-- Mostrar los archivos adjuntos si existen -->
                          <div class="list-group-item list-group-item-action">
                            <div class="d-flex w-100 justify-content-between">
                              <h5 class="mb-1" style="cursor: pointer;">
                                <a [href]="editForm.get('formulario_url')?.value" target="_blank">Formulario adjunto</a>
                              </h5>
                              <small>
                                <b class="mr-3">{{editForm.get('formulario')?.value}}</b><br>
                              </small>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>


            <div class="col-12">
              <button class="btn btn-lg btn-block btn-primary" type="submit">Actualizar</button>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>






<!-- Modal Nueva  -->
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNuevaSolicitud"
  aria-labelledby="offcanvasNuevaSolicitudLabel">
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel"><i class="fa fa-passport"> </i> Nueva Solicitud</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="row">
      <div class="col-12">
        <h5>Seleccione cliente <br></h5>
      </div>

      <div class="col-12">
        <div class="form-group">
          <!-- <label class="form-label">Buscar Cliente</label> -->
          <input [(ngModel)]="buscador" type="text" class="form-control" placeholder="Buscar Cliente">
        </div>

        <div *ngIf="!buscador"></div>

        <ul class="list-group mb-2" *ngIf="buscador">
          <button *ngFor="let item of listClientes | filtroCliente:buscador" type="button"
            class="list-group-item list-group-item-action" (click)="selectCliente(item)">
            <div class="mb-2 fw-bold">{{ item.nombre | titlecase }}</div>
            <div class="d-flex w-100 ">
              <small><i class="fa fa-envelope"></i> {{item.email}}</small>
            </div>
            <div class="d-flex w-100 ">
              <small><i class="fa fa-mobile-alt"></i> {{item.celular}}</small>
            </div>
          </button>

          <!-- <button class="list-group-item list-group-item-action text-bold mb-2 mt-2"
              style="background:#0d6efd; color:white;font-size: 1.0em" (click)="createCliente(buscador)">
              <i class="fa fa-user-plus"> </i> CREA UN CLIENTE.
            </button> -->
        </ul>

        <div *ngIf="clienteSeleccionado?.id" class="p-5 bg-primary text-center">
          <h4>{{clienteSeleccionado.nombre | titlecase}}</h4>
        </div>
      </div>

      <!-- <form [formGroup]="nuevoClienteForm" (ngSubmit)="newCliente()">
          <div class="col-12 mt-3" id="crearCliente" style="display: none;">
            <div class="card">
              <div class="card-body">
                <h5>Cliente</h5>
                <div class="form-group">
                  <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control" />
                </div>

                <div class="form-group">
                  <input formControlName="cc" placeholder="Nit. - C.C" type="text" class="form-control" />
                </div>

                <div class="form-group">
                  <input formControlName="email" placeholder="Email" type="text" class="form-control" />
                </div>

                <div class="form-group">
                  <input formControlName="direccion" placeholder="Direccion" type="text" class="form-control" />
                </div>

                <div class="form-group">
                  <input formControlName="celular" placeholder="Celular" type="text" class="form-control" />
                </div>
                <button type='submit'> Crear Cliente </button>
              </div>
            </div>
          </div>
        </form> -->
      <!-- <div class="col-12">
          <button type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal" data-bs-toggle="modal"
            data-bs-target="#nuevo"><i class="fa fa-chevron-left"></i> Atrás</button>
        </div>         -->



      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5>Seleccione tipo visa</h5>
            <div class="row">
              <div class="col-4" *ngFor="let item of listFormato">
                <img class="img-fluid img-thumbnail" src="{{item.img_url}}" style="max-width: 80px;cursor: pointer;"
                  (click)="selectFormatoSend(item)">
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="selectFormato?.id">
          <div class="card-body">
            <div>Formato seleccionado</div>
            <div class="row">
              <div class="col-3">
                <img class="img-fluid img-thumbnail" src="{{selectFormato.img_url}}" style="max-width: 80px;">
              </div>
              <div class="col-9">
                <h4>{{selectFormato?.nombre | titlecase}}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button type="button" [disabled]="!clienteSeleccionado?.id || !selectFormato?.id" class="btn btn-block mb-2 mt-2"
      style="background:#0d6efd; color:white;font-size: 1.0em" (click)="crearSolicitud()">
      <i class="fa fa-passport"> </i> CREA SOLICITUD VISA.
    </button>

  </div>
</div>


<section>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#interesados" type="button"
        role="tab" aria-controls="interesados" aria-selected="true" (click)="getListVisas()">Interesados</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#proceso" type="button" role="tab"
        aria-controls="proceso" aria-selected="false" (click)="getListVisasEnproceso()">En Proceso</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#agendado" type="button" role="tab"
        aria-controls="agendado" aria-selected="false" (click)="getListVisasAgendado()">Agendado</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#finalizado" type="button"
        role="tab" aria-controls="finalizado" aria-selected="false"
        (click)="getListVisasFinalizado()">Finalizado</button>
    </li>
  </ul>

  <div class="tab-content" id="myTabContent">
    <!-- TAB interesados -->
    <div class="tab-pane fade show active  bg-body" id="interesados" role="tabpanel" aria-labelledby="recientes-tab">
      <div class="container bg-body">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Visa</th>
              <th scope="col">Nombre</th>
              <th scope="col">Contacto</th>
              <th scope="col">Visto</th>
              <th scope="col">Estado</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listInteresados.items" (click)="verVisa(item)" style="cursor: pointer;">
              <th scope="row">{{item.id}}</th>
              <td><img class="img-fluid img-thumbnail" src="{{item.img}}" style="max-width: 30px;"></td>
              <td>{{item.nombre | titlecase}}</td>
              <td>{{item.cliente_celular}}</td>
              <td><span style="color: rgb(35, 165, 253)" *ngIf="item.visita_cliente">{{item.visita_cliente}} <i
                    class="fa fa-check" style="margin-right: -5px;"></i><i class="fa fa-check"></i></span></td>
              <td>
                <span *ngIf="item.estado == -1" class="badge bg-danger">Cancelada</span>
                <span *ngIf="item.estado == 3" class="badge bg-success">Procesada</span>
                <span *ngIf="item.estado == 2" class="badge bg-warning">Pendiente</span>
                <span *ngIf="!item.estado_solicitud" class="badge bg-primary">Pendiente</span>
              </td>
              <td><button type="button" class="btn btn-sm btn-outline-danger" *ngIf="user.perfil =='2fk6i2o3143'"
                  (click)="deleteVisa(item.id)"><i class="fa fa-trash"></i></button></td>
            </tr>
          </tbody>
        </table>

        <!-- paginador listInteresados -->
        <div class="card-footer clearfix">
          <ul class="pagination pagination-sm m-0 float-right">
            <li class="page-item" *ngIf="listInteresados.current > 1">
              <button type="button" class="page-link" (click)="changePage(listInteresados.current - 1)">
                <span>Atras</span>
              </button>
            </li>

            <li class="page-item" *ngFor="let page of pagesNumber"
              [ngClass]="{'active': listInteresados.current == page}">
              <button type="button" class="page-link" (click)="changePage(page)">
                {{ page }}
              </button>
            </li>

            <li class="page-item" *ngIf="listInteresados.current < listInteresados.total_page">
              <button type="button" class="page-link" (click)="changePage(listInteresados.next)">
                <span>Siguiente</span>
              </button>
            </li>
          </ul>
          <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
            <p>Total registros encontrados: <b>{{listInteresados.size}}</b></p>
          </div>
        </div>
        <!-- Fin paginador -->
      </div>
    </div>


    <!-- TAB proceso -->
    <div class="tab-pane fade" id="proceso" role="tabpanel" aria-labelledby="proceso-tab">
      <div class="container bg-body">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Visa</th>
              <th scope="col">Nombre</th>
              <th scope="col">Contacto</th>
              <th scope="col">Personas</th>
              <th scope="col">Fotos</th>
              <th scope="col">Formulario</th>
              <th scope="col">Pasaporte</th>
              <th scope="col">Visto</th>
              <th scope="col">Estado</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listEnProceso.items" (click)="verVisa(item)" style="cursor: pointer;">
              <th scope="row">{{item.id}}</th>
              <td><img class="img-fluid img-thumbnail" src="{{item.img}}" style="max-width: 30px;"></td>
              <td>{{item.nombre | titlecase}}</td>
              <td>{{item.cliente_celular}}</td>
              <td class="text-center">{{item.personas}}</td>
              <td class="text-center">
                <ng-container *ngIf="item.foto">
                  <i class="fa fa-check" style="color: green;"></i>
                </ng-container>
              </td>
              <td class="text-center">
                <ng-container *ngIf="item.formulario">
                  <i class="fa fa-check" style="color: green;"></i>
                </ng-container>
              </td>
              <td class="text-center">
                <ng-container *ngIf="item.pasaporte">
                  <i class="fa fa-check" style="color: green;"></i>
                </ng-container>
              </td>

              <td><span style="color: rgb(35, 165, 253)" *ngIf="item.visita_cliente">{{item.visita_cliente}} <i
                    class="fa fa-check" style="margin-right: -5px;"></i><i class="fa fa-check"></i></span></td>
              <td>
                <span *ngIf="item.estado_solicitud == 1" class="badge bg-warning">En Proceso</span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- paginador listEnProceso -->
        <div class="card-footer clearfix">
          <ul class="pagination pagination-sm m-0 float-right">
            <li class="page-item" *ngIf="listEnProceso.current > 1">
              <button type="button" class="page-link" (click)="changePageListProceso(listEnProceso.current - 1)">
                <span>Atras</span>
              </button>
            </li>

            <li class="page-item" *ngFor="let page of pagesNumberEnProceso"
              [ngClass]="{'active': listEnProceso.current == page}">
              <button type="button" class="page-link" (click)="changePageListProceso(page)">
                {{ page }}
              </button>
            </li>

            <li class="page-item" *ngIf="listEnProceso.current < listEnProceso.total_page">
              <button type="button" class="page-link" (click)="changePageListProceso(listEnProceso.next)">
                <span>Siguiente</span>
              </button>
            </li>
          </ul>
          <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
            <p>Total registros encontrados: <b>{{listEnProceso.size}}</b></p>
          </div>
        </div>
        <!-- Fin paginador -->

      </div>
    </div>

    <!-- TAB agendado -->
    <div class="tab-pane fade  bg-body" id="agendado" role="tabpanel" aria-labelledby="agendado-tab">
      <div class="container bg-body">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Visa</th>
              <th scope="col">Nombre</th>
              <th scope="col">Contacto</th>
              <th scope="col">Personas</th>
              <th scope="col">Fecha Cita 1</th>
              <th scope="col">Fecha Cita 2</th>
              <th scope="col">Visto</th>
              <th scope="col">Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listAgendado.items" (click)="verVisa(item)" style="cursor: pointer;">
              <th scope="row">{{item.id}}</th>
              <td><img class="img-fluid img-thumbnail" src="{{item.img}}" style="max-width: 30px;"></td>
              <td>{{item.nombre | titlecase}}</td>
              <td>{{item.cliente_celular}}</td>
              <td class="text-center">{{item.personas}}</td>
              <td>{{ item.fecha_cita1 | date: 'yyyy-MM-dd h:mm a' }}</td>
              <td>{{ item.fecha_cita2 | date: 'yyyy-MM-dd h:mm a' }}</td>
              <td><span style="color: rgb(35, 165, 253)" *ngIf="item.visita_cliente">{{item.visita_cliente}} <i
                    class="fa fa-check" style="margin-right: -5px;"></i><i class="fa fa-check"></i></span></td>
              <td>
                <span *ngIf="item.estado_solicitud == 2" class="badge bg-success">Agendado</span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- paginador listAgendado -->
        <div class="card-footer clearfix">
          <ul class="pagination pagination-sm m-0 float-right">
            <li class="page-item" *ngIf="listAgendado.current > 1">
              <button type="button" class="page-link" (click)="changePageListAgendado(listAgendado.current - 1)">
                <span>Atras</span>
              </button>
            </li>

            <li class="page-item" *ngFor="let page of pagesNumberAgendado"
              [ngClass]="{'active': listAgendado.current == page}">
              <button type="button" class="page-link" (click)="changePageListAgendado(page)">
                {{ page }}
              </button>
            </li>

            <li class="page-item" *ngIf="listAgendado.current < listAgendado.total_page">
              <button type="button" class="page-link" (click)="changePageListAgendado(listAgendado.next)">
                <span>Siguiente</span>
              </button>
            </li>
          </ul>
          <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
            <p>Total registros encontrados: <b>{{listAgendado.size}}</b></p>
          </div>
        </div>
        <!-- Fin paginador -->
      </div>
    </div>

    <!-- TAB finalizado -->
    <div class="tab-pane fade  bg-body" id="finalizado" role="tabpanel" aria-labelledby="finalizado-tab">
      <div class="container bg-body">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Visa</th>
              <th scope="col">Nombre</th>
              <th scope="col">Contacto</th>
              <th scope="col">Fecha Cita 1</th>
              <th scope="col">Fecha Cita 2</th>
              <th scope="col">Visto</th>
              <th scope="col">Estado</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listFinalizado.items">
              <th scope="row" (click)="verVisa(item)" style="cursor: pointer;">{{item.id}}</th>
              <td (click)="verVisa(item)" style="cursor: pointer;"><img class="img-fluid img-thumbnail"
                  src="{{item.img}}" style="max-width: 30px;"></td>
              <td (click)="verVisa(item)" style="cursor: pointer;">{{item.nombre | titlecase}}</td>
              <td (click)="verVisa(item)" style="cursor: pointer;">{{item.cliente_celular}}</td>
              <td (click)="verVisa(item)" style="cursor: pointer;">{{ item.fecha_cita1 | date: 'yyyy-MM-dd h:mm a' }}
              </td>
              <td (click)="verVisa(item)" style="cursor: pointer;">{{ item.fecha_cita2 | date: 'yyyy-MM-dd h:mm a' }}
              </td>
              <td><span style="color: rgb(35, 165, 253)" *ngIf="item.visita_cliente">{{item.visita_cliente}} <i
                    class="fa fa-check" style="margin-right: -5px;"></i><i class="fa fa-check"></i></span></td>
              <td (click)="verVisa(item)" style="cursor: pointer;">
                <span *ngIf="item.estado_solicitud == 3" class="badge bg-danger">Denegada</span>
                <span *ngIf="item.estado_solicitud == 4" class="badge bg-success">Aprobada</span>
              </td>
              <td><button type="button" class="btn btn-sm btn-outline-danger" *ngIf="user.perfil =='2fk6i2o3143'"
                  (click)="deleteVisa(item.id)"><i class="fa fa-trash"></i></button></td>
            </tr>
          </tbody>
        </table>

        <!-- paginador listFinalizado -->
        <div class="card-footer clearfix">
          <ul class="pagination pagination-sm m-0 float-right">
            <li class="page-item" *ngIf="listFinalizado.current > 1">
              <button type="button" class="page-link" (click)="changePageListFinalizado(listFinalizado.current - 1)">
                <span>Atras</span>
              </button>
            </li>

            <li class="page-item" *ngFor="let page of pagesNumberFinalizado"
              [ngClass]="{'active': listFinalizado.current == page}">
              <button type="button" class="page-link" (click)="changePageListFinalizado(page)">
                {{ page }}
              </button>
            </li>

            <li class="page-item" *ngIf="listFinalizado.current < listFinalizado.total_page">
              <button type="button" class="page-link" (click)="changePageListFinalizado(listFinalizado.next)">
                <span>Siguiente</span>
              </button>
            </li>
          </ul>
          <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
            <p>Total registros encontrados: <b>{{listFinalizado.size}}</b></p>
          </div>
        </div>
        <!-- Fin paginador -->

      </div>
    </div>
  </div>


  <!-- Modal Ver Visa -->
  <div class="modal fade" id="vervisamodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center">Detalle solicitud de Visa</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <h2>{{VisaSeleccionada?.nombre | titlecase}} <img class="img-fluid img-thumbnail"
                  src="{{VisaSeleccionada?.img}}" style="max-width: 60px;">
                <a href="visa-publica/{{VisaSeleccionada?.token}}" class="ml-1 btn btn-secondary" target="_blank">
                  <i class="fa fa-eye"></i>
                </a>
                <a *ngIf="VisaSeleccionada?.cliente_celular"
                  href="https://wa.me/57{{VisaSeleccionada?.cliente_celular}}?text=https://aplicacion.faroapp.com.co/visa-publica/{{VisaSeleccionada?.token}}"
                  target="_blank">
                  <img class="img-fluid img-thumbnail ml-1" src="/assets/img/whatsapp.png" style="max-width: 42px;">
                </a>

              </h2>
            </div>
            <div class="col-8">
              <h6>
                Asesor Comercial: <b>
                  {{VisaSeleccionada?.usuario | titlecase}}</b>
              </h6>
            </div>
            <div class="col-4">{{VisaSeleccionada?.visa_at}}</div>
          </div>

          <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
            <li class="nav-item" role="presentationModal">
              <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#general" type="button"
                role="tab" aria-controls="general" aria-selected="true">HISTORIAL</button>
            </li>
            <li class="nav-item" role="presentationModal">
              <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#adjuntos" type="button"
                role="tab" aria-controls="adjuntos" aria-selected="false">ADJUNTOS</button>
            </li>
          </ul>

          <div class="tab-content" id="myTabContent">

            <!-- TAB APARIENCIA -->
            <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">

              <div class="row">
                <div class="col-8">
                  <div class="row">
                    <div class="col-12">
                      <div class="mb-3">
                        <label for="detalle" class="form-label">Detalle</label>
                        <textarea class="form-control" id="detalle" rows="3" placeholder="Escribe los detalles aquí"
                          [(ngModel)]="detalle_historial"></textarea>
                      </div>
                      <button type="button" class="btn btn-primary w-100" [disabled]="!detalle_historial"
                        (click)="sendDetalleVisa(VisaSeleccionada?.id)">Agregar</button>
                    </div>
                    <div class="col-12">
                      <div class="row mt-2">
                        <div class="col-12" *ngFor="let item of VisaSeleccionada?.historial; let i=index">
                          <div class="card mb-3 shadow-sm">
                            <div class="card-body">
                              <h5 class="card-title">{{item.detalle}}</h5>
                              <p class="card-text text-sm">
                                {{item.usuario}}<br>
                                {{item.visa_historial_at | date:'dd/MM/yyyy HH:mm'}} <!-- Con el pipe date -->
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
                <div class="col-4">
                  <div class="card mt-4">
                    <h4 class="card-title ml-2 mt-2 text-center"
                      *ngIf="VisaSeleccionada?.estado_solicitud !=2 || VisaSeleccionada?.estado_solicitud !=3 || VisaSeleccionada?.estado_solicitud !=4">
                      <b>Actualizar Estado Solicitud</b>
                    </h4>
                    <div class="card-body">
                      <div class="row">

                        <div class="col-12 mt-2" *ngIf="!VisaSeleccionada?.estado_solicitud">
                          <button type="button" class="btn btn-primary btn-sm w-100"
                            (click)="actualizarVisa(VisaSeleccionada?.id,1)" [disabled]="!personas || !valor_total">
                            En Proceso
                          </button>
                        </div>

                        <div class="col-12 mt-2" *ngIf="VisaSeleccionada?.estado_solicitud == 1">
                          <button type="button" class="btn btn-success btn-sm w-100"
                            (click)="actualizarVisa(VisaSeleccionada?.id,2)" [disabled]="!fecha_cita1">Agendado</button>
                        </div>
                      </div>

                      <div *ngIf="VisaSeleccionada?.estado_solicitud == 2">
                        <div class="text-center" style="font-size: 1.2em;font-weight: bold;">Cual fue el resultado final
                          de la solicitud</div>
                        <div class="d-flex justify-content-between">
                          <button type="button" class="btn btn-success"
                            (click)="actualizarVisa(VisaSeleccionada?.id,4)">Aprobada</button>
                          <button type="button" class="btn btn-danger"
                            (click)="actualizarVisa(VisaSeleccionada?.id,3)">Denegada</button>
                        </div>
                      </div>

                      <div *ngIf="VisaSeleccionada?.estado_solicitud == 3 || VisaSeleccionada?.estado_solicitud == 4">
                        <h4 class="text-center">Su solicitud de visa fue
                          <span *ngIf="VisaSeleccionada?.estado_solicitud == 3" style="color: red;">Denegada</span>
                          <span *ngIf="VisaSeleccionada?.estado_solicitud == 4" style="color: green;">Aprobada</span>
                        </h4>
                      </div>


                    </div>
                  </div>

                  <!-- Card fecha citas -->
                  <div class="card mt-4" *ngIf="VisaSeleccionada?.estado_solicitud == 1">
                    <div class="card-body">
                      <div class="row">

                        <div class="col-12 mt-2">
                          <div class="form-group">
                            <div for="fecha_cita1">Fecha y Hora de Citación 1</div>
                            <input id="fecha_cita1" name="fecha_cita1" type="datetime-local" class="form-control"
                              [(ngModel)]="fecha_cita1" style="font-size: 1.2em;" />
                          </div>
                        </div>

                        <div class="col-12 mt-2">
                          <div class="form-group">
                            <div for="fecha_cita2">Fecha y Hora de Citación 2 (opcional)</div>
                            <input id="fecha_cita2" name="fecha_cita2" type="datetime-local" class="form-control"
                              [(ngModel)]="fecha_cita2" style="font-size: 1.2em;" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card mt-4" *ngIf="VisaSeleccionada?.estado_solicitud == 2">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <div style="font-size: 1.3em;">Fecha citación 1</div>
                            <span><b>{{ fecha_cita1 | date: 'd \'de\' MMMM, y, h:mm a' }}</b></span>
                          </div>
                        </div>

                        <div class="col-12 mt-2">
                          <div class="form-group">
                            <div style="font-size: 1.3em;">Fecha citación 2 (opcional)</div>
                            <span><b>{{ fecha_cita2 | date: 'd \'de\' MMMM, y, h:mm a' }}</b></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- card valor persona -->
                  <div class="card mt-4" *ngIf="!VisaSeleccionada?.estado_solicitud">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 mt-2">
                          <label for="exampleFormControlInput1" class="form-label">Valor Servicio</label>
                          <input (input)="calcularTotal()" [(ngModel)]="valor_servicio" currencyMask
                            [options]="{ prefix: '$ ', thousands: '.', decimal: ',', precision: 0}" class="form-control"
                            placeholder=">Valor Servicio" style="font-size: 1.8em;">

                        </div>
                        <div class="col-12 mt-2">
                          <label for="exampleFormControlInput1" class="form-label">Personas</label>
                          <input [(ngModel)]="personas" (input)="calcularTotal()" type="number"
                            class="form-control text-right" style="font-size: 1.5em;" />
                        </div>

                        <div class="col-12 mt-2">
                          <label for="exampleFormControlInput1" class="form-label">Total Servicio</label>
                          <h3 class="text-right">${{valor_total | decimal}}</h3>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="card mt-4" *ngIf="VisaSeleccionada?.estado_solicitud">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-6">
                          <label for="exampleFormControlInput1" class="form-label text-right">Valor Servicio</label>
                        </div>
                        <div class="col-6">
                          <div class="text-right" style="font-size: 1.2em;">${{valor_servicio | decimal}}</div>
                        </div>
                        <div class="col-6">
                          <label for="exampleFormControlInput1" class="form-label">Personas</label>
                        </div>
                        <div class="col-6">
                          <div class="text-right" style="font-size: 1.2em;">{{personas}}</div>
                        </div>

                        <div class="col-6">
                          <label for="exampleFormControlInput1" class="form-label">Total Servicio</label>
                        </div>
                        <div class="col-6">
                          <h2 class="text-right">${{valor_total | decimal}}</h2>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <!-- TAB ADJUNTO -->
            <div class="tab-pane fade" id="adjuntos" role="tabpanel" aria-labelledby="adjuntos-tab">

              <div class="row">

                <div class="col-4">
                  <div class="row">
                    <div class="col-10">
                      <div class="form-floating mb-2">
                        <input [(ngModel)]="adjunto_nombre" placeholder="Nombre del adjunto" type="text"
                          class="form-control" />
                        <label for="floatingNombre">Nombre del adjunto <span class="text-danger">*</span></label>
                      </div>
                    </div>
                    <div class="col-2 p-0">
                      <button type="button" *ngIf="loadingImg" class="btn btn-block btn-primary p-3 m-0">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </button>

                      <button *ngIf="!loadingImg" [disabled]="!adjunto_nombre"
                        class="btn btn-block btn-primary p-3 m-0 " (click)="fileUploadAdjuntos.click()"><i
                          class="fa fa-upload"></i></button>
                      <input #fileUploadAdjuntos type="file" accept='application/pdf, image/*'
                        (change)="uploadArchivo(fileUploadAdjuntos.files)" style="display: none" />
                    </div>
                  </div>
                  <small class="text-muted">Cargar Adjunto {{adjunto_archivo}}</small>


                  <div class="col-12 mt-5">
                    <div class="form-check form-switch switch-large">
                      <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckFoto"
                        [(ngModel)]="foto" (change)="actualizarVisa(VisaSeleccionada?.id, estado_solicitud)">
                      <label class="form-check-label" for="flexSwitchCheckFoto">Fotos</label>
                    </div>

                    <div class="form-check form-switch switch-large">
                      <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckPasaporte"
                        [(ngModel)]="pasaporte" (change)="actualizarVisa(VisaSeleccionada?.id, estado_solicitud)">
                      <label class="form-check-label" for="flexSwitchCheckPasaporte">Pasaporte</label>
                    </div>

                    <div class="form-check form-switch switch-large">
                      <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckFormulario"
                        [(ngModel)]="formulario" (change)="actualizarVisa(VisaSeleccionada?.id, estado_solicitud)">
                      <label class="form-check-label" for="flexSwitchCheckFormulario">Formularios</label>
                    </div>
                  </div>



                </div>

                <div class="col-8">
                  <div class="list-group">
                    <!-- Mostrar mensaje si no hay adjuntos -->
                    <div *ngIf="listAdjuntosVisas.length === 0" class="alert alert-primary" role="alert">
                      No tiene archivos adjuntos en esta solicitud de visa.
                    </div>

                    <!-- Mostrar los archivos adjuntos si existen -->
                    <div class="list-group-item list-group-item-action"
                      *ngFor="let item of listAdjuntosVisas; let i=index">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1" style="cursor: pointer;">
                          <a [href]="item.archivo_url" target="_blank">{{item.nombre_archivo | titlecase}}</a>
                        </h5>
                        <small>
                          <b class="mr-3">{{item.extension}}</b>
                          <button class="btn btn-sm btn-outline-primary" type="button" (click)="deleteAdjunto(item)">
                            <i class="fa fa-trash"></i>
                          </button>
                        </small>
                      </div>
                      <small>
                        {{item.archivo}}
                        <div>{{item.visa_adjunto_at}}</div>
                      </small>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>

      </div>
    </div>
  </div>
</section>