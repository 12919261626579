<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-8">
        <div class="row">
          <div class="col-6">
            <h1>
              Cotizaciones
              <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#nuevo"
                (click)="resetAll()"><i class="fa fa-plus"></i></button>
              <button *ngIf="!cargando" type="button" class="btn btn-sm btn-primary ml-1" (click)="getList()">
                <i class="fas fa-sync"></i>
              </button>
              <button *ngIf="cargando" type="button" class="btn btn-sm btn-primary ml-1">
                <i class="fa fa-sync-alt fa-spin"></i>
              </button>

            </h1>
          </div>
          <div class="col-6">
            <div class="input-group mb-0">
              <input type="text" class="form-control" [(ngModel)]="findInput" placeholder="Buscador">
              <button class="btn btn-secondary" type="button" id="button-addon2" (click)="findCotizacion()"><i
                  class="fa fa-search"></i></button>
            </div>
          </div>
        </div>

      </div>
      <div class="col-sm-4">
        <ol class="breadcrumb float-sm-right">

          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item active">Cotizaciones</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content m-2">
  <div class="card">
    <div class="card-body p-0 ">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">FECHA</th>
            <th scope="col">CLIENTE</th>
            <th scope="col">CONTACTO</th>
            <th scope="col">DESTINO</th>
            <th scope="col">VISTO</th>
            <th scope="col">Estado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of cotizaciones.items">
            <td style="cursor: pointer;" (click)="ver(item)">{{item.id}}</td>
            <td style="cursor: pointer;" (click)="ver(item)">{{item.cotizacion_at}}</td>
            <td style="cursor: pointer;" (click)="ver(item)">{{item.nombre | titlecase}}</td>
            <td style="cursor: pointer;" (click)="ver(item)">{{item.celular}}</td>
            <td style="cursor: pointer;" (click)="ver(item)">{{item.destino}}</td>
            <td class="text-primary"><i class="fa fa-check-double"></i> <b class="ml-1">{{item.visita_cliente}}</b></td>
            <td><span class="badge bg-success" *ngIf="item.estado ==1">Activo</span></td>
            <td>
              <div class="dropdown">
                <button class="btn btn-block btn-light dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"></button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><span class="dropdown-item" style="cursor: pointer;" (click)="ver(item)">Ver Cotización</span>
                  </li>
                  <li><span class="dropdown-item"><a href="reserva/{{item.id}}" target="_blank"
                        style="color: #000;">Tarjeta Reserva Publica</a></span></li>
                  <!-- <li><span class="dropdown-item text-danger" *ngIf="user.perfil =='2fk6i2o3143'"
                      style="cursor: pointer;" (click)="eliminarTarjeta(item)">Eliminar</span></li> -->
                </ul>
              </div>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- paginador -->
  <div class="card-footer clearfix">
    <ul class="pagination pagination-sm m-0 float-right">
      <li class="page-item" *ngIf="cotizaciones.current > 1">
        <button type="button" class="page-link" (click)="changePage(cotizaciones.current - 1)">
          <span>Atras</span>
        </button>
      </li>

      <li class="page-item" *ngFor="let page of pagesNumber" [ngClass]="{'active': cotizaciones.current == page}">
        <button type="button" class="page-link" (click)="changePage(page)">
          {{ page }}
        </button>
      </li>

      <li class="page-item" *ngIf="cotizaciones.current < cotizaciones.total_page">
        <button type="button" class="page-link" (click)="changePage(cotizaciones.next)">
          <span>Siguiente</span>
        </button>
      </li>
    </ul>
    <div class="pagination" style="height: auto !important; margin: 10px;margin-top:0px;">
      <p>Total registros encontrados: <b>{{cotizaciones.size}}</b></p>
    </div>
  </div>
  <!-- Fin paginador -->

</section>


<!-- Modal nuevo  -->
<div class="modal fade" id="nuevo" tabindex="-1" aria-labelledby="nuevoLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="nuevoLabel">Cotización</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="card">
          <h5 class="card-header">
            <button class="btn btn-sm btn-secondary" type="button" data-bs-toggle="modal"
              data-bs-target="#buscarClienteModal"><i class="fa fa-plus"></i></button> <span class="ml-2"
              data-bs-toggle="modal" data-bs-target="#buscarClienteModal" style="cursor: pointer;">Seleccione
              Cliente</span>
          </h5>
          <!-- CLIENTE -->
          <div class="card-body">
            <div class="row">
              <form [formGroup]="nuevoForm" (ngSubmit)="nuevoCotizacion()">
                <div class="row">
                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input formControlName="nombre" type="text" readonly class="form-control" placeholder=">Nombre">
                      <label>Nombre</label>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input formControlName="cc" type="text" readonly class="form-control" placeholder=">Nit. / C.C">
                      <label>Nit. / C.C</label>
                    </div>

                  </div>

                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input formControlName="email" type="text" readonly class="form-control" placeholder="Email">
                      <label>Email</label>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input formControlName="direccion" type="text" readonly class="form-control"
                        placeholder=">Dirección">
                      <label>Dirección</label>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-floating mb-3">
                      <input formControlName="celular" type="text" readonly class="form-control"
                        placeholder=">Teléfono">
                      <label>Teléfono</label>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
          <!-- FIN CARD -->
          <div>
            <button class="btn btn-lg btn-block btn-primary" type="button"
              (click)="nuevoCotizacion()">Siguiente</button>
          </div>
        </div>



      </div>
    </div>
  </div>
</div>


<!-- Modal buscar y crear CLIENTES -->
<div class="modal fade" id="buscarClienteModal" tabindex="-1" aria-labelledby="buscarClienteModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Seleccione Cliente</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">Buscar cliente</label>
              <input [(ngModel)]="buscador" type="text" (keydown)="getBuscarCliente()" class="form-control">
            </div>

            <div *ngIf="!buscador"></div>

            <ul class="list-group" *ngIf="buscador">
              <button *ngFor="let item of listClientes" type="button" class="list-group-item list-group-item-action"
                (click)="selectCliente(item)" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#nuevo">
                <div class="mb-2 fw-bold">{{ item.nombre | titlecase }}</div>
                <div class="d-flex w-100 ">
                  <small><i class="fa fa-envelope"></i> {{item.email}}</small>
                  <small><i class="fa fa-mobile-alt ml-4"></i> {{item.celular}}</small>
                </div>
              </button>

              <button class="list-group-item list-group-item-action text-bold mb-2 mt-2"
                style="background:#0d6efd; color:white;font-size: 1.0em" (click)="createCliente(buscador)">
                <i class="fa fa-user-plus"> </i> CREA UN CLIENTE.
              </button>
            </ul>
          </div>
          <form [formGroup]="nuevoClienteForm" (ngSubmit)="newCliente()">
            <div class="col-12 mt-3" id="crearCliente" style="display: none;">
              <div class="card">
                <div class="card-body">
                  <h5>Cliente</h5>
                  <div class="form-group">
                    <input formControlName="nombre" placeholder="Nombre" type="text" class="form-control" />
                  </div>

                  <div class="form-group">
                    <input formControlName="cc" placeholder="Nit. - C.C" type="text" class="form-control" />
                  </div>

                  <div class="form-group">
                    <input formControlName="email" placeholder="Email" type="text" class="form-control" />
                  </div>

                  <div class="form-group">
                    <input formControlName="direccion" placeholder="Direccion" type="text" class="form-control" />
                  </div>

                  <div class="form-group">
                    <input formControlName="celular" placeholder="Celular" type="text" class="form-control" />
                  </div>

                  <app-button
                    [disabled]="!nuevoClienteForm.value.nombre || !nuevoClienteForm.value.celular || !nuevoClienteForm.value.email"
                    [type]="'submit'" [block]="true" [loading]="cargando">
                    Crear Cliente
                  </app-button>
                </div>
              </div>
            </div>
          </form>
          <div class="col-12">
            <button type="button" class="btn btn-secondary btn-block" data-bs-dismiss="modal" data-bs-toggle="modal"
              data-bs-target="#nuevo"><i class="fa fa-chevron-left"></i> Atrás</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal VER  -->
<div class="modal fade" id="ver" tabindex="-1" aria-labelledby="verLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="nuevoLabel">Cotización<b></b>
          <!-- <span class="ml-5 text-danger"><i class="fa fa-exclamation-triangle"></i> No se han guardado los cambios </span> -->
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <div class="modal-body">
        <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#interesados"
              type="button" role="tab" aria-controls="interesados" aria-selected="true">GENERAL</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="politicas-tab" data-bs-toggle="tab" data-bs-target="#politicas" type="button"
              role="tab" aria-controls="politicas" aria-selected="false">POLITICAS</button>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#proceso" type="button"
              role="tab" aria-controls="proceso" aria-selected="false">ABONOS</button>
          </li> -->

        </ul>


        <div class="tab-content" id="myTabContent">
          <!-- TAB GENERAL -->
          <div class="tab-pane fade show active  bg-body" id="interesados" role="tabpanel"
            aria-labelledby="recientes-tab">

            <!-- CONTACTO GENERAL -->
            <div class="card">
              <h5 class="card-header">
                Cliente
                <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="modal"
                  data-bs-target="#buscarClienteModal"><i class="fa fa-plus"></i></button>
              </h5>
              <!-- CLIENTE -->
              <div class="card-body">
                <div class="row">
                  <form [formGroup]="editForm" (ngSubmit)="editarCotizacion()">
                    <div class="row">
                      <!-- <app-button [type]="'submit'" [block]="true" [loading]="isAuthLoading">Actualizar Cliente</app-button>                        -->
                      <div class="col-4">
                        <div class="form-floating mb-3">
                          <input formControlName="nombre" type="text" readonly class="form-control"
                            placeholder=">Nombre">
                          <label>Nombre</label>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-floating mb-3">
                          <input formControlName="cc" type="text" readonly class="form-control"
                            placeholder=">Nit. / C.C">
                          <label>Nit. / C.C</label>
                        </div>

                      </div>

                      <div class="col-4">
                        <div class="form-floating mb-3">
                          <input formControlName="email" type="text" readonly class="form-control" placeholder="Email">
                          <label>Email</label>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-floating mb-3">
                          <input formControlName="direccion" type="text" readonly class="form-control"
                            placeholder=">Dirección">
                          <label>Dirección</label>
                        </div>
                      </div>

                      <div class="col-4">
                        <div class="form-floating mb-3">
                          <input formControlName="celular" type="text" readonly class="form-control"
                            placeholder=">Teléfono">
                          <label>Teléfono</label>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div class="card">

              <div class="card-body">
                <div class="container py-4">
                  <div class="row g-3">
                    <!-- Origen -->
                    <div class="col-12 col-md-6">
                      <label for="origen" class="form-label"><b>Origen</b></label>
                      <input type="text" [(ngModel)]="origen" id="origen" class="form-control" placeholder="" />
                    </div>

                    <!-- Destino -->
                    <div class="col-12 col-md-6">
                      <label for="destino" class="form-label"><b>Destino</b></label>
                      <input type="text" [(ngModel)]="destino" id="destino" class="form-control" placeholder="" />
                    </div>

                    <!-- Partida -->
                    <div class="col-12 col-md-6">
                      <label for="partida" class="form-label"><b>Partida</b></label>
                      <div class="input-group">
                        <input type="date" [(ngModel)]="partida" id="partida" class="form-control" placeholder="dd/mm/aaaa" />
                      </div>
                    </div>

                    <!-- Regreso -->
                    <div class="col-12 col-md-6">
                      <label for="regreso"  class="form-label"><b>Regreso</b></label>
                      <div class="input-group">
                        <input type="date" [(ngModel)]='regreso' id="regreso" class="form-control" placeholder="dd/mm/aaaa" />
                      </div>
                    </div>

                    <!-- Título -->
                    <div class="col-12">
                      <label for="titulo" class="form-label"><b>Título</b></label>
                      <input type="text" [(ngModel)]='titulo' id="titulo" class="form-control" placeholder="" />
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- fin tab -->

          </div>

          <!-- TAB POLITICAS -->
          <div class="tab-pane fade" id="politicas" role="tabpanel" aria-labelledby="politicas-tab">
            <div class="row">
              <div class="col-8">
                <div class="text-center">
                  <h4 class="text-center">Politicas Cotización</h4>
                  <!-- <i class="fa fa-gavel" style="font-size: 9em;color: #ddd;margin-top: 15%;"></i> -->
                </div>

                <!-- <div class="list-group">
                  <div class="list-group-item list-group-item-action"
                    *ngFor="let item of listPoliticasTarjetaReserva; let i=index">
                    <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1" style="cursor: pointer;">{{item.nombre | titlecase}}</h6>
                      <small><button class="btn btn-sm btn-danger" type="button" (click)="deletePolitica(item)"><i
                            class="fa fa-trash"></i></button></small>
                    </div>
                  </div>
                </div> -->

              </div>
              <div class="col-4">
                <h5 class="text-center">Politicas Agencia</h5>
                <div class="list-group">
                  <div class="list-group-item list-group-item-action" *ngFor="let item of listPoliticas; let i=index">
                    <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1" style="cursor: pointer;">{{item.nombre | titlecase}}</h6>
                      <small><button class="btn btn-sm btn-primary" type="button" (click)="addPolitica(item)"><i
                            class="fa fa-plus"></i></button></small>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>