<div class="float-right d-none d-sm-block" data-bs-toggle="modal" data-bs-target="#updateModal" style="cursor: pointer;"><b>Versión</b> 2.51</div>
<strong>
    <span>Copyright &copy; {{ currentYear }}</span>
    <a
        href="https://servidigital.com.co"
        target="_blank"
        rel="noopener noreferrer"
        style="margin: 0"
    >
        servidigital.com.co</a
    >
    <span>.</span>
</strong>
<!-- <span> All rights reserved.</span> appVersion -->


  <!-- Modal -->
  <div class="modal fade" id="updateModal" tabindex="-1" aria-labelledby="updateModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="updateModalLabel">Forzar Actualización en Google Chrome</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h6>Para macOS:</h6>
          <p>Presiona <kbd>Cmd (⌘) + Shift + R</kbd> o <kbd>Cmd (⌘) + R</kbd> mientras mantienes presionada la tecla <kbd>Shift</kbd>.</p>
          <h6>Para Windows:</h6>
          <p>Presiona <kbd>Ctrl + F5</kbd> o <kbd>Ctrl + Shift + R</kbd>.</p>
          <p>Estos atajos te permiten forzar la actualización de la página sin utilizar la caché del navegador y de esta manera se muestra la nueva versión.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  