import { Component, OnInit } from '@angular/core';
import { AppService } from '@services/app.service';
import { userInfo } from 'os';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    public user;
    public menu = MENU;
    // public menubase = MENUBASE;
    permisos: any;

    menuBase: any[] = [];
    menuFiltrado: any[] = [];

    constructor(public appService: AppService) { }

    ngOnInit() {
        this.user = this.appService.user;
        console.log(this.user.md);
        console.log(this.user);

        this.permisos = {
            md_clientes: this.user.md_clientes, // acceso permitido
            md_cotizaciones: this.user.md_cotizaciones, // acceso no permitido
            md_iata: this.user.md_aita,
            md_plantillas: this.user.md_plantillas,
            md_politicas: this.user.md_politicas,
            md_proveedores: this.user.md_proveedores,
            md_reservas: this.user.md_reservas,
            md_solicitudes: this.user.md_solicitudes,
            md_usuario: this.user.md_usuario,
            md_visas: this.user.md_visas,
        };


        this.menuBase = [
            { name: 'Operaciones', path: ['/'], icon: 'fa-tachometer-alt', perfil: '15kwqgk3123', permiso: '' },
            { name: 'Empresas', path: ['/empresas'], icon: 'fa-city', perfil: '15kwqgk3123', permiso: '' },
        
            { name: 'Operaciones', path: ['/'], icon: 'fa-tachometer-alt', perfil: '2fk6i2o3143', permiso: '' },
            { name: 'Solicitudes', path: ['/solicitudes'], icon: 'fa-calendar-check', perfil: '2fk6i2o3143', permiso: 'md_solicitudes' },
            { name: 'Cotizaciones', path: ['/cotizaciones'], icon: 'fa-user', perfil: '2fk6i2o3143', permiso: 'md_cotizaciones' },
            { name: 'Reservas', path: ['/reservas'], icon: 'fa-money-check', perfil: '2fk6i2o3143', permiso: 'md_reservas' },
            { name: 'Visas', path: ['/visas'], icon: 'fa-passport', perfil: '2fk6i2o3143', permiso: 'md_visas' },
            { name: 'Proveedores', path: ['/proveedores'], icon: 'fa-boxes', perfil: '2fk6i2o3143', permiso:'md_proveedores' },
            { name: 'IATA', path: ['/codigo_iata'], icon: 'fa-code', perfil: '2fk6i2o3143', permiso: 'md_iata' },
            { name: 'Plantillas', path: ['/plantillas'], icon: 'fa-magis', perfil: '2fk6i2o3143', permiso: 'md_cotizaciones' },            
            { name: 'Clientes', path: ['/clientes'], icon: 'fa-user-friends', perfil: '2fk6i2o3143', permiso: 'md_clientes' },
            { name: 'Politicas', path: ['/politicas'], icon: 'fa-gavel', perfil: '2fk6i2o3143', permiso:'md_politicas' },
            { name: 'Usuarios', path: ['/usuarios'], icon: 'fa-users', perfil: '2fk6i2o3143', permiso: 'md_usuario' },
        
            { name: 'Operaciones', path: ['/'], icon: 'fa-tachometer-alt', perfil: '3af3s12f153', permiso: '' },
            { name: 'Solicitudes', path: ['/solicitudes'], icon: 'fa-calendar-check', perfil: '3af3s12f153', permiso: 'md_solicitudes' },
            { name: 'Cotizaciones', path: ['/cotizaciones'], icon: 'fa-user', perfil: '3af3s12f153', permiso: 'md_cotizaciones' },
            { name: 'Reservas', path: ['/reservas'], icon: 'fa-money-check', perfil: '3af3s12f153', permiso: 'md_reservas' },
            { name: 'Visas', path: ['/visas'], icon: 'fa-passport', perfil: '3af3s12f153', permiso: 'md_visas' },
            { name: 'Proveedores', path: ['/proveedores'], icon: 'fa-boxes', perfil: '3af3s12f153', permiso: 'md_proveedores' },
            { name: 'IATA', path: ['/codigo_iata'], icon: 'fa-code', perfil: '3af3s12f153', permiso: 'md_iata' },
            { name: 'Clientes', path: ['/clientes'], icon: 'fa-user-friends', perfil: '3af3s12f153', permiso: 'md_clientes' },
        ];        

        this.menuFiltrado = this.menuBase.filter(item => !item.permiso || this.permisos[item.permiso]);
        console.log('menu sidebar'); // Verifica el resultado filtrado
        // console.log(this.menuFiltrado); // Verifica el resultado filtrado

    }
}


// export const MENU = [
//     // this.menuFiltrado
// ];



export const MENU = [
    { name: 'Operaciones', path: ['/'], icon: 'fa-tachometer-alt', perfil: '15kwqgk3123', permiso: '' },
    { name: 'Empresas', path: ['/empresas'], icon: 'fa-city', perfil: '15kwqgk3123', permiso: '' },

    { name: 'Operaciones', path: ['/'], icon: 'fa-tachometer-alt', perfil: '2fk6i2o3143', permiso: '' },
    { name: 'Solicitudes', path: ['/solicitudes'], icon: 'fa-calendar-check', perfil: '2fk6i2o3143', permiso: 'md_solicitudes' },
    { name: 'Cotizaciones', path: ['/cotizaciones'], icon: 'fa-user', perfil: '2fk6i2o3143', permiso: 'md_cotizaciones' },
    { name: 'Reservas', path: ['/reservas'], icon: 'fa-money-check', perfil: '2fk6i2o3143', permiso: 'md_reservas' },
    { name: 'Visas', path: ['/visas'], icon: 'fa-passport', perfil: '2fk6i2o3143', permiso: 'md_visas' },
    { name: 'Proveedores', path: ['/proveedores'], icon: 'fa-boxes', perfil: '2fk6i2o3143', permiso:'md_proveedores' },
    { name: 'IATA', path: ['/codigo_iata'], icon: 'fa-code', perfil: '2fk6i2o3143', permiso: 'md_iata' },
    { name: 'Plantillas', path: ['/plantillas'], icon: 'fa-magic', perfil: '2fk6i2o3143', permiso: 'md_cotizaciones' },    
    { name: 'Clientes', path: ['/clientes'], icon: 'fa-user-friends', perfil: '2fk6i2o3143', permiso: 'md_clientes' },
    { name: 'Politicas', path: ['/politicas'], icon: 'fa-gavel', perfil: '2fk6i2o3143', permiso:'md_politicas' },
    { name: 'Usuarios', path: ['/usuarios'], icon: 'fa-users', perfil: '2fk6i2o3143', permiso: 'md_usuario' },

    { name: 'Operaciones', path: ['/'], icon: 'fa-tachometer-alt', perfil: '3af3s12f153', permiso: '' },
    { name: 'Solicitudes', path: ['/solicitudes'], icon: 'fa-calendar-check', perfil: '3af3s12f153', permiso: 'md_solicitudes' },
    { name: 'Cotizaciones', path: ['/cotizaciones'], icon: 'fa-user', perfil: '3af3s12f153', permiso: 'md_cotizaciones' },
    { name: 'Reservas', path: ['/reservas'], icon: 'fa-money-check', perfil: '3af3s12f153', permiso: 'md_reservas' },
    { name: 'Visas', path: ['/visas'], icon: 'fa-passport', perfil: '3af3s12f153', permiso: 'md_visas' },
    { name: 'Proveedores', path: ['/proveedores'], icon: 'fa-boxes', perfil: '3af3s12f153', permiso: 'md_proveedores' },
    { name: 'IATA', path: ['/codigo_iata'], icon: 'fa-code', perfil: '3af3s12f153', permiso: 'md_iata' },
    { name: 'Clientes', path: ['/clientes'], icon: 'fa-user-friends', perfil: '3af3s12f153', permiso: 'md_clientes' },
];





// { name: 'Main Menu', icon: 'fa-list-ul',
//     children: [
//         {
//             name: 'Sub Menu',
//             path: ['/sub-menu-1']
//         },

//         {
//             name: 'Blank',
//             path: ['/sub-menu-2']
//         }
//     ]
// }
