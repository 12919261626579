<style>
  .container-fluid {
    /* background: rgb(33,150,243);
  background: radial-gradient(circle, rgba(33,150,243,1) 0%, rgba(33,95,243,1) 50%, rgba(69,120,252,1) 100%); */
    background-color: #fff !important;

    background-image: url('assets/img/bg1.png');
    background-size: cover;

    /* background-color: #18468e !important; */
  }

  .container {
    background-color: #2196f3 !important;
  }
</style>
<div class="container-fluid pt-0 pb-3">
  <div class="row d-flex justify-content-center">
    <div class="col-md-10">
      <div>
        <img *ngIf="solicitud_visa?.archivo_url" [src]="solicitud_visa?.archivo_url" class="img-fluid w-100">
      </div>
      <div class="card">
        <div [style.backgroundColor]="solicitud_visa?.empresa_color_principal" class="row m-0">
          <!-- Columna izquierda -->
          <h1 class="col-sx-12 col-md-5">
            <span *ngIf="solicitud_visa?.empresa_logo">
              <img class="mt-3 img-fluid" [src]="solicitud_visa.empresa_logo" />
            </span>
            <!-- {{solicitud_visa?.empresa?.nombre}} -->
          </h1>

          <!-- Columna central -->
          <div class="col-sx-12 col-md-5 mb-2" style="color: white;">
            <div class="font-weight-bold" style="font-size: 1.4em;">Solicitud de Visado #{{solicitud_visa?.id}}</div>
            <div class="font-weight-bold" style="font-size: 1.2em;">{{solicitud_visa?.empresa?.direccion | titlecase}}
            </div>
            <div>
              <small style="font-size: 1.1em;">Celular: {{solicitud_visa?.empresa?.telefono | titlecase}}</small>
            </div>
            <small style="font-size: 1.1em;">Email: {{solicitud_visa?.empresa?.email | titlecase}}</small>
          </div>

          <!-- Columna derecha para la imagen del usuario -->
          <div class="col-sx-12 col-md-2 text-center">
            <img *ngIf="solicitud_visa?.usuario_img" class="img-fluid img-thumbnail mt-3"
              [src]="solicitud_visa?.usuario_img" alt="Usuario" style="max-width: 75px;">
          </div>
        </div>
        <div class="table-responsive">
          <div class="row">
            <div class="col-12 text-center pt-2" style="background-color: #f9cc33;">
              <h2>Hola {{solicitud_visa?.nombre | titlecase}} </h2>
            </div>
            <div class="col-12 text-center mt-2 mb-2">
              <h4>Aquí encontraras, la información que deseas:</h4>
            </div>
            <div class="col-12 text-center pb-2" style="background-color: whitesmoke;">
              <h2><b><img *ngIf="solicitud_visa?.img" class="img-fluid img-thumbnail" src="{{solicitud_visa?.img}}"
                    style="max-width: 45px;">
                  {{solicitud_visa?.visa_nombre | titlecase}}
                </b>
              </h2>
              <span class="badge bg-secondary fs-4" *ngIf="!solicitud_visa?.estado_solicitud ">Su Solicitud se encuentra
                Pendiente</span>
              <span class="badge bg-info fs-4" *ngIf="solicitud_visa?.estado_solicitud == 1">Su Solicitud se encuentra
                En Proceso</span>
              <span class="badge bg-warning fs-4" *ngIf="solicitud_visa?.estado_solicitud == 2">Su Solicitud se
                encuentra Agendado</span>
              <span class="badge bg-danger fs-4" *ngIf="solicitud_visa?.estado_solicitud == 3">Su Solicitud fue
                Denegada</span>
              <span class="badge bg-success fs-4" *ngIf="solicitud_visa?.estado_solicitud == 4">Su Solicitud fue
                Aprobada</span>

              <div class="mt-2" *ngIf="solicitud_visa?.estado_solicitud == 2">
                <div class="*ngIf=solicitud_visa?.fecha_cita1">Fecha de la entrevisata en el consulado</div>
                <b>{{ solicitud_visa?.fecha_cita1 | date: 'd \'de\'
                  MMMM, y, h:mm a' }}</b>

                  <div *ngIf="solicitud_visa?.fecha_cita2" class="mt-2">Fecha de la entrevista 2</div>
                  <b>{{ solicitud_visa?.fecha_cita2 | date: 'd \'de\'
                    MMMM, y, h:mm a' }}</b>                  
              </div>

            </div>

          </div>
        </div>

        <div class="table-responsive">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <!-- Pestaña Visas Requisitos -->
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="visas-tab" data-bs-toggle="tab" data-bs-target="#visas" type="button"
                role="tab" aria-controls="visas" aria-selected="true">Visas Requisitos</button>
            </li>
            <!-- Pestaña Visas Requisitos Entrevista -->
            <li class="nav-item" role="presentation" *ngIf="solicitud_visa?.visa_entrevista">
              <button class="nav-link" id="visas-tab" data-bs-toggle="tab" data-bs-target="#entrevista" type="button"
                role="tab" aria-controls="visas" aria-selected="true">Documentos Entrevista</button>
            </li>
            <!-- Pestaña Solicitud Personal -->
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="solicitud-tab" data-bs-toggle="tab" data-bs-target="#solicitud" type="button"
                role="tab" aria-controls="solicitud" aria-selected="false">Solicitud Personal</button>
            </li>
            <!-- Pestaña Adjuntos -->
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="adjuntos-tab" data-bs-toggle="tab" data-bs-target="#adjuntos" type="button"
                role="tab" aria-controls="adjuntos" aria-selected="false">Adjuntos</button>
            </li>
          </ul>

          <div class="tab-content mt-3" id="myTabContent">
            <!-- Contenido de la pestaña Visas Requisitos -->
            <div class="tab-pane fade show active" id="visas" role="tabpanel" aria-labelledby="visas-tab">
              <!-- Contenido de Visas Requisitos aquí -->
              <div class="col-12 text-center mt-4 mb-0">
                <h4>Conoce los Requisitos</h4>
              </div>
              <div class="col-12 p-5" [innerHTML]="solicitud_visa?.visa_requisitos"></div>

            </div>

            <!-- Contenido de la pestaña Visas Requisitos Entrevisata -->
            <div class="tab-pane fade" id="entrevista" role="tabpanel" aria-labelledby="visas-tab">
              <!-- Contenido de Visas Requisitos aquí -->
              <div class="col-12 text-center mt-4 mb-0">
                <h4>Conoce los Requisitos de la entrevista</h4>
              </div>
              <div class="col-12 p-5" [innerHTML]="solicitud_visa?.visa_entrevista"></div>

            </div>

            <!-- Contenido de la pestaña Solicitud Personal -->
            <div class="tab-pane fade" id="solicitud" role="tabpanel" aria-labelledby="solicitud-tab">
              <!-- Contenido de Solicitud Personal aquí -->
              <div class="p-2">
                <h5 class="add p-2 text-center" style="background-color: #f9cc33; font-size: 1.5em;">
                  Detalle Solicitud
                </h5>
                <table class="table table-bordered">
                  <thead style="background-color: #f9cc33; font-size: 1.7em;">
                    <tr>
                      <th>Descripción</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody style="font-size: 1.5em;">
                    <tr>
                      <td>Costos Consulares</td>
                      <td><b>{{ solicitud_visa?.visa_costo}}</b></td> <!-- Muestra valor en formato de moneda -->
                    </tr>
                    <tr>
                      <td>Personas</td>
                      <td><b>{{ solicitud_visa?.personas}}</b></td> <!-- Muestra valor en formato de moneda -->
                    </tr>
                    <tr>
                      <td>Costo Servicio</td>
                      <td><b *ngIf="solicitud_visa?.valor_total">${{ solicitud_visa?.valor_total | decimal}}</b></td>
                      <!-- Muestra valor en formato de moneda -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Contenido de la pestaña Adjuntos -->
            <div class="tab-pane fade" id="adjuntos" role="tabpanel" aria-labelledby="adjuntos-tab">
              <!-- Contenido de Adjuntos aquí -->

              <div class="products p-2">

                <div class="products p-2">
                  <h5 class="add p-2 text-center" style="background-color: #f9cc33; font-size: 1.5em;">
                    <i class="fa fa-file-upload"></i> ADJUNTOS
                  </h5>

                  <table class="table table-bordered" *ngIf="solicitud_visa?.adjuntos?.length > 0; else noAdjuntos">
                    <tbody>
                      <tr class="add">
                        <td>#</td>
                        <td>Archivo Adjunto</td>
                        <td class="text-center">Extensión</td>
                      </tr>
                      <tr class="content" *ngFor="let item of solicitud_visa?.adjuntos; let i=index"
                        style="cursor: pointer;font-size: 1.5em;">
                        <td>{{i + 1}}</td>
                        <td><a [href]="item.archivo_url" target="_blank">{{item.nombre_archivo | titlecase}}</a></td>
                        <td class="text-center"><a [href]="item.archivo_url" target="_blank">{{item.archivo |
                            titlecase}}</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <!-- Aviso si no hay adjuntos -->
                  <ng-template #noAdjuntos>
                    <div class="alert alert-warning text-center" role="alert">
                      No hay adjuntos disponibles.
                    </div>
                  </ng-template>
                </div>

                <hr>
              </div>
            </div>
          </div>
        </div>

        <a *ngIf="solicitud_visa?.formulario_url" [href]="solicitud_visa?.formulario_url" class="btn btn-warning btn-lg btn-block text-white" target="_blank">
          <i class="fa fa-download"></i> Descargar Formulario
        </a>

        <div class="table-responsive mt-1" style="background-color: #f9cc33; font-size: 1em;">
          <h6 class="text-center">Nota: Para efectos de cualquier información y/o confirmación por favor referirse al
            siguiente número de Solicitud de Visado #{{solicitud_visa?.id}}</h6>
        </div>

      </div>
    </div>
  </div>