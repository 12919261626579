<style>
  .container-fluid {
    background-color: #fff !important;
    background-image: url('assets/img/bg1.png');
    background-size: cover;
  }

  .container {
    background-color: #2196f3 !important;
  }
</style>
<div class="container-fluid pt-0 pb-3">
  <div class="row d-flex justify-content-center">
    <div class="col-md-10">
      <div>
        <!-- <img *ngIf="solicitud_visa?.archivo_url" [src]="solicitud_visa?.archivo_url" class="img-fluid w-100"> -->
      </div>
      <div class="card">
        <div [style.backgroundColor]="solicitud_visa?.empresa_color_principal" class="row m-0">
          <!-- Columna izquierda -->
          <h1 class="col-sx-12 col-md-5">
            <span *ngIf="solicitud_visa?.empresa_logo">
              <img class="mt-3 img-fluid" [src]="solicitud_visa.empresa_logo" />
            </span>
            <!-- {{solicitud_visa?.empresa?.nombre}} -->
          </h1>

          <!-- Columna central -->
          <div class="col-sx-12 col-md-5 mb-2" style="color: white;">
            <div class="font-weight-bold" style="font-size: 1.4em;">Solicitud de Visado #{{solicitud_visa?.id}}</div>
            <div class="font-weight-bold" style="font-size: 1.2em;">{{solicitud_visa?.empresa?.direccion | titlecase}}
            </div>
            <div>
              <small style="font-size: 1.1em;">Celular: {{solicitud_visa?.empresa?.telefono | titlecase}}</small>
            </div>
            <small style="font-size: 1.1em;">Email: {{solicitud_visa?.empresa?.email | titlecase}}</small>
          </div>

        </div>
        <div class="table-responsive">
          <div class="row">
            <div class="col-12 text-center pt-2" style="background-color: #f9cc33;height: 30px;">
              <!-- <h2>Solicitud</h2> -->
            </div>
            <div class="col-12 text-center mt-2 mb-2">
              <h4>Aquí encontraras, la información que deseas:</h4>
            </div>
            <div class="col-12 text-center pb-2" style="background-color: whitesmoke;">
              <h2><b><img *ngIf="solicitud_visa?.archivo_url" class="img-fluid img-thumbnail" src="{{solicitud_visa?.archivo_url}}"
                    style="max-width: 45px;">
                  {{solicitud_visa?.nombre | titlecase}}
                </b>
              </h2>
            </div>

          </div>
        </div>

        <div class="table-responsive">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <!-- Pestaña Visas Requisitos -->
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="visas-tab" data-bs-toggle="tab" data-bs-target="#visas" type="button"
                role="tab" aria-controls="visas" aria-selected="true">Visas Requisitos</button>
            </li>
            <!-- Pestaña Visas Requisitos Entrevista -->
            <li class="nav-item" role="presentation" *ngIf="solicitud_visa?.entrevista">
              <button class="nav-link" id="visas-tab" data-bs-toggle="tab" data-bs-target="#entrevista" type="button"
                role="tab" aria-controls="visas" aria-selected="true">Documentos Entrevista</button>
            </li>

          </ul>

          <div class="tab-content mt-3" id="myTabContent">
            <!-- Contenido de la pestaña Visas Requisitos -->
            <div class="tab-pane fade show active" id="visas" role="tabpanel" aria-labelledby="visas-tab">
              <!-- Contenido de Visas Requisitos aquí -->
              <div class="col-12 text-center mt-4 mb-0">
                <h4>Conoce los Requisitos</h4>
              </div>
              <div class="col-12 p-5" [innerHTML]="solicitud_visa?.requisitos"></div>

              <a *ngIf="solicitud_visa?.formulario_url" [href]="solicitud_visa?.formulario_url" class="btn btn-warning btn-lg btn-block text-white" target="_blank">
                <i class="fa fa-download"></i> Descargar Formulario
              </a>
            </div>

            <!-- Contenido de la pestaña Visas Requisitos Entrevisata -->
            <div class="tab-pane fade" id="entrevista" role="tabpanel" aria-labelledby="visas-tab">
              <!-- Contenido de Visas Requisitos aquí -->
              <div class="col-12 text-center mt-4 mb-0">
                <h4>Conoce los Requisitos de la entrevista</h4>
              </div>
              <div class="col-12 p-5" [innerHTML]="solicitud_visa?.entrevista"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>