import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AppService } from '@services/app.service';
import moment from 'moment';
import confetti from 'canvas-confetti';


@Component({
  selector: 'app-visa-formato',
  templateUrl: './visa-formato.component.html',
  styleUrls: ['./visa-formato.component.scss']
})
export class VisaFormatoComponent implements OnInit {
  id:any;
  solicitud_visa: any;
  empresa: any;
  noches: any;
  politicaNombre: any;
  politicaDescripcion: any;
  
  constructor(private route: ActivatedRoute,public router: Router,
    public appService: AppService) {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.id = this.route.snapshot.paramMap.get('id');
        console.log(this.id);
        if(this.id){
          this.getFormato(); 
        }else{
          // this.router.navigate(['/catalogo/']);
        } 

      });
   }

  ngOnInit(): void {
  }

  getFormato(){
    let params = {
      getFormato:true,
      id: this.id,
    };
    this.appService.postVisaFormato(params).subscribe( (data) => {      
      this.solicitud_visa = data['obj']; 
      console.log(this.solicitud_visa);   
    })
  }






}
