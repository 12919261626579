<div class="container py-4">
  <div class="row">
    <!-- Panel de Elementos -->
    <div class="col-2">
      <h6>Elementos disponibles</h6>
      <div *ngFor="let element of elements" class="mb-2">
        <button class="btn btn-outline-secondary w-100" (click)="addElementToZone(element)">
          {{ element.label }}
        </button>
      </div>
    </div>

    <!-- Zona de Construcción -->
    <div class="col-10">
      <h6>Zona de Construcción</h6>
      <div id="constructionArea" class="construction-area border p-3">
        <ng-container *ngFor="let item of droppedItems; let i = index">
          <div class="mb-2 p-2 border position-relative" draggable="true" (dragstart)="onDragStart($event, item, i)"
            (dragover)="allowDrop($event)" (drop)="onDrop($event, i)">

            <button class="btn btn-danger btn-sm position-absolute end-0 top-0 m-1" (click)="removeItem(i)">
              ×
            </button>

            <ng-container [ngSwitch]="item.type">
              <!-- Botón -->
              <button *ngSwitchCase="'button'" class="{{ item.class }}">
                {{ item.label }}
              </button>

              <!-- Texto -->
              <p *ngSwitchCase="'text'" class="{{ item.class }}">
                {{ item.label }}
              </p>

              <!-- Imagen -->
              <img *ngSwitchCase="'image'" [src]="item.src" alt="{{ item.label }}" class="img-fluid mb-2"
                style="max-width: 100px;">

              <!-- Grid Box -->
              <div *ngSwitchCase="'grid-box'">
                <div class="d-flex justify-content mb-2">
                  <button class="btn btn-sm btn-info mr-1" (click)="setGridColumns(item, 1)">1 Col</button>
                  <button class="btn btn-sm btn-info mr-1" (click)="setGridColumns(item, 2)">2 Col</button>
                  <button class="btn btn-sm btn-info mr-1" (click)="setGridColumns(item, 3)">3 Col</button>
                  <button class="btn btn-sm btn-info mr-1" (click)="setGridColumns(item, 4)">4 Col</button>
                </div>
                <div class="row">
                  <div class="col" *ngFor="let col of [].constructor(item.columns); let colIndex = index">
                    <div class="border p-2 mb-2" (dragover)="allowDrop($event)" (drop)="onDrop($event, 0, item)">
                      <p>Columna {{ colIndex + 1 }}</p>
                      <ng-container *ngFor="let child of item.items; let childIndex = index">
                        <div class="p-2 border mb-1 draggable-item" draggable="true"
                          (dragstart)="onDragStart($event, child, childIndex, false)">
                          <!-- <button class="btn btn-danger btn-sm position-absolute end-0 top-0 m-1"
                            (click)="removeItem(childIndex, item)">
                            ×
                          </button> -->
                          {{ child.label }}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>